<div class="company-register-page">

    <div class="container">
        <h1 class="page-heading text-center mt-5 mb-4" *ngIf="!isDone">Business Registration</h1>
        <form [formGroup]="registerCompanyForm">
            <mat-stepper labelPosition="bottom" #stepper *ngIf="!isDone">
                <!-- TAB 1    -->
                <mat-step>
                    <ng-template matStepLabel>ACCOUNT</ng-template>
                    <div class="row mt-2">
                        <div class="text-center upload-logo-btn" *ngIf="!uploadedLogoSrc">
                            <label for="thumbnail-upload">
                                <img src="../../../../assets/images/Upload Photo.png" alt=""
                                    class="icon-btn cursor-pointer">
                                <h3 class="upload-text">Upload company logo</h3>
                            </label>
                            <input type="file" id="thumbnail-upload" hidden accept="image/jpeg, image/png, image/jpg"
                                #thumbnailImport (change)="selectLogo($event)">
                        </div>
                        <div class="w-100">
                            <div class="position-relative image-holder" *ngIf="uploadedLogoSrc">
                                <div>
                                    <div class="text-center upload-logo-btn">
                                        <div class="icon-btn text-center">
                                            <img [src]="uploadedLogoSrc" alt="" class="w-100 h-100">
                                            <mat-icon class="delete-icon cursor-pointer"
                                                (click)="uploadedLogoSrc = null">delete_outline</mat-icon>
                                        </div>
                                    </div>
                                </div>
                                <div class="" *ngIf="uploadedLogoSrc">

                                    <label for="thumbnail-upload">
                                        <img src="../../../../assets/images/Upload Photo.png" alt=""
                                            class="position-absolute small-uploaded-btn-icon cursor-pointer">
                                    </label>
                                    <input type="file" id="thumbnail-upload" hidden
                                        accept="image/jpeg, image/png, image/jpg" #thumbnailImport
                                        (change)="selectLogo($event)">
                                </div>
                            </div>
                        </div>




                        <div class="col-md-6">
                            <div>
                                <mat-form-field class="example-full-width input-style w-100" appearance="outline">
                                    <mat-label>Company name*</mat-label>
                                    <input matInput formControlName="org_name">
                                </mat-form-field>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div>
                                <mat-form-field class="example-full-width input-style w-100" appearance="outline">
                                    <mat-label>GSTN no</mat-label>
                                    <input matInput formControlName="org_gstin">
                                </mat-form-field>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div>
                                <div>
                                    <mat-form-field class="example-full-width input-style w-100" appearance="outline">
                                        <mat-label>Company type</mat-label>
                                        <mat-select formControlName="org_type">
                                            <mat-option value="Private Limited Company">Private Limited
                                                Company</mat-option>
                                            <mat-option value="Public Limited Company">Public Limited
                                                Company</mat-option>
                                            <mat-option value="One Person Company">One Person Company</mat-option>
                                            <mat-option value="Limited Liability Partnership">Limited Liability
                                                Partnership</mat-option>
                                            <mat-option value="Partnership Firm">Partnership Firm</mat-option>
                                            <mat-option value="Proprietorship Firm">Proprietorship Firm</mat-option>
                                            <mat-option value="Hindu Undivided Family">Hindu Undivided
                                                Family</mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <mat-form-field class="example-full-width input-style w-100 mobile-number-field"
                                appearance="outline" floatLabel="always">
                                <mat-label>Mobile Number*</mat-label>
                                <div class="country-code-container">
                                    <app-int-tel-picker (countryChange)="selectCountry($event)"
                                    [countryEdit]="countryEdit"
                                    (click)="stopPropagation($event)"></app-int-tel-picker>
                                    <input matInput formControlName="org_contact_phone_number">
                                </div>
                                <mat-error
                                    *ngIf="registerCompanyForm.get('org_contact_phone_number').invalid && registerCompanyForm.get('org_contact_phone_number').hasError('pattern')">
                                    <div class="d-flex align-items-center">
                                        <img src="../../../../../../assets/images/cautiom.svg" alt="" class="me-1">
                                        <span class="caution">Enter 10 digits</span>
                                    </div>
                                </mat-error>
                            </mat-form-field>
                        </div>
                        <div class="col-md-6">
                            <mat-form-field class="example-full-width input-style w-100" appearance="outline">
                                <mat-label>Email *</mat-label>
                                <input matInput formControlName="org_contact_email_address">
                                <mat-error *ngIf="registerCompanyForm.get('org_contact_email_address').invalid">
                                    <div class="d-flex align-items-center">
                                        <img src="../../../../../../assets/images/cautiom.svg" alt="" class="me-1">
                                        <span class="caution">Enter valid email</span>
                                    </div>
                                </mat-error>
                            </mat-form-field>
                        </div>
                        <div class="col-md-6">
                            <div>
                                <mat-form-field class="example-full-width input-style w-100" appearance="outline">
                                    <mat-label>Organisation Business Name</mat-label>
                                    <input matInput  formControlName="org_business_name">
                                </mat-form-field>
                            </div>
                        </div>

                        <div class="col-md-6">
                            <div>
                                <mat-form-field class="example-full-width input-style w-100" appearance="outline">
                                    <mat-label>Website</mat-label>
                                    <input matInput  formControlName="org_website">
                                </mat-form-field>
                            </div>
                        </div>

                        <div class="col-md-6">
                            <div>
                                <mat-form-field class="example-full-width input-style w-100" appearance="outline">
                                    <mat-label>TIN Number</mat-label>
                                    <input matInput formControlName="tin_number" >
                                </mat-form-field>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div>
                                <mat-form-field class="example-full-width input-style w-100" appearance="outline">
                                    <mat-label>PAN Number</mat-label>
                                    <input matInput formControlName="pan" (keydown.enter)="preventEnter($event)"
                                    (input)="registerCompanyForm.get('pan').setValue($event.target.value.toUpperCase(), {emitEvent: false})">
                                </mat-form-field>
                            </div>
                        </div>

                        <div>
                        </div>
                    </div>
                    <div class="d-flex align-items-center radio-margin want-address">
                        <div class="radio-btn-design cursor-pointer" [class.active-redio]="isRadioBtnActive"
                            (click)="toggleRadioBtn()"></div>
                        <h4 class="radio-btn-lebel mb-0 ms-2 cursor-pointer" (click)="toggleRadioBtn()">Want to enter
                            company
                            address?</h4>
                    </div>
                    <div *ngIf="ischeck" class=" check-margin">
                        <div class="row">

                            <div class="col-md-6">
                                <mat-form-field class="example-full-width input-style w-100" appearance="outline">
                                    <mat-label>Building number </mat-label>
                                    <input matInput formControlName="org_addr_line1">
                                </mat-form-field>
                            </div>
                            <div class="col-md-6">
                                <mat-form-field class="example-full-width input-style w-100" appearance="outline">
                                    <mat-label>Society/Flat/Area/Road</mat-label>
                                    <input matInput formControlName="org_addr_line2">
                                </mat-form-field>
                            </div>
                            <div class="col-md-6">
                                <mat-form-field class="example-full-width input-style w-100" appearance="outline">
                                    <mat-label>Zip code </mat-label>
                                    <input matInput formControlName="org_addr_post_code">
                                    <mat-error
                                        *ngIf="registerCompanyForm.get('org_addr_post_code').hasError('pattern')">
                                        <div class="d-flex align-items-center">
                                            <img src="../../../../../../assets/images/cautiom.svg" alt="" class="me-1">
                                            <span class="caution">Enter 6 digits</span>
                                        </div>
                                    </mat-error>
                                </mat-form-field>
                            </div>

                            <div class="col-md-6">
                                <mat-form-field class="example-full-width input-style w-100"
                                appearance="outline">
                                <mat-label>Country</mat-label>
                                <mat-select formControlName="org_addr_country" (selectionChange)="onCountryChange($event)">

                                    <div class="menu-search">
                                        <div class="position-relative  country-list  search-area-width">
                                            <i
                                                class="fa fa-search position-absolute search-icon-btn"></i>
                                            <input matInput class="search-input-from-list"
                                                placeholder="Search country" [(ngModel)]="countrySearch"
                                                (click)="stopPropagation($event)"
                                                [ngModelOptions]="{standalone: true}"
                                                (input)="filterCountries()" />
                                        </div>
                                    </div>

                                    <ul class="m-0 p-0 items-ul country-list items-list-width  position-relative">
                                        <mat-option *ngFor="let country of filteredCountries"
                                            [value]="country">{{country?.name}}</mat-option>
                                    </ul>
                                </mat-select>
                            </mat-form-field>
                            </div>

                            <div class="col-md-6">
                                <mat-form-field class="example-full-width input-style w-100"
                                    appearance="outline">
                                    <mat-label>State</mat-label>
                                    <mat-select formControlName="org_addr_state"
                                     (selectionChange)="onStateChange($event)">
                                        <mat-option  *ngFor="let state of states" [value]="state">{{state?.name}}</mat-option>
                                    </mat-select>
                                </mat-form-field>
                            </div>

                            <div class="col-md-6" *ngIf="registerCompanyForm.get('org_addr_state').value">
                                <mat-form-field class="example-full-width input-style w-100"
                                    appearance="outline">
                                    <mat-label>City</mat-label>
                                    <mat-select formControlName="org_addr_city"  (selectionChange)="onCityChange($event)"> 
                                        <mat-option *ngFor="let city of cities"
                                            [value]="city">{{city?.name}}</mat-option>
                                    </mat-select>
                                </mat-form-field>
                            </div>

                            <!-- <div class="col-md-6">
                                <mat-form-field class="example-full-width input-style w-100" appearance="outline">
                                    <mat-label>State</mat-label>
                                    <mat-select formControlName="org_addr_state" (selectionChange)="onStateChange()">
                                        <mat-option *ngFor="let state of stateCityList"
                                            [value]="state.key">{{state.key}}</mat-option>
                                    </mat-select>
                                </mat-form-field>
                            </div>
                            <div class="col-md-6" *ngIf="registerCompanyForm.get('org_addr_state').value">
                                <mat-form-field class="example-full-width input-style w-100" appearance="outline">
                                    <mat-label>City</mat-label>
                                    <mat-select formControlName="org_addr_city">


                                        <mat-option *ngFor="let city of selectedStateCities"
                                            [value]="city">{{city}}</mat-option>
                                    </mat-select>
                                </mat-form-field>
                            </div> -->
                        </div>
                    </div>
                    
                    <div class="row">

                        <div class="col-md-6 d-flex flex-md-column-btn">
                            <div class="w-100 mt-2 mb-5 mt-20-btn">
                                <button mat-button class="strok-blue-btn  btn" matStepperPrevious
                                    routerLink="/select-company">Back</button>
                            </div>
                            <div class="w-100 mt-2 ms-lg-3 mt-20-btn">

                                <button mat-button class="blue-btn btn" matStepperNext
                                    [disabled]="registerCompanyForm.get('org_name').invalid || registerCompanyForm.get('org_contact_email_address').invalid || registerCompanyForm.get('org_contact_phone_number').invalid">Next</button>
                            </div>

                        </div>
                    </div>


                </mat-step>
                <!-- TAB 2    -->
                <mat-step>
                    <ng-template matStepLabel>BANK DETAILS</ng-template>
                    <div class="row mt-2">

                        <div class="col-md-6">
                            <mat-form-field class="example-full-width input-style w-100" appearance="outline">
                                <mat-label>Bank Account Number</mat-label>
                                <input matInput [(ngModel)]="accountNumber" [ngModelOptions]="{standalone: true}">
                            </mat-form-field>
                        </div>
                        <div class="col-md-6">
                            <mat-form-field class="example-full-width input-style w-100" appearance="outline">
                                <mat-label>Account Type</mat-label>
                                <mat-select [(ngModel)]="accountType" [ngModelOptions]="{standalone: true}">
                                    <mat-option value="Savings Account">Savings Account</mat-option>
                                    <mat-option value="Current Account">Current Account</mat-option> </mat-select>
                            </mat-form-field>
                        </div>
                        <div class="col-md-6">
                            <mat-form-field class="example-full-width input-style w-100" appearance="outline">
                                <mat-label>IFSC Code</mat-label>
                                <input matInput [(ngModel)]="ifscCode" [ngModelOptions]="{standalone: true}">

                            </mat-form-field>
                        </div>
                        <div class="col-md-6">
                            <mat-form-field class="example-full-width input-style w-100" appearance="outline">
                                <mat-label>Bank Name </mat-label>
                                <input matInput [(ngModel)]="bankName" [ngModelOptions]="{standalone: true}">
                            </mat-form-field>
                        </div>

                        <div class="col-md-6">
                            <mat-form-field class="example-full-width input-style w-100" appearance="outline">
                                <mat-label>Branch Name</mat-label>
                                <input matInput [(ngModel)]="branchName" [ngModelOptions]="{standalone: true}">
                            </mat-form-field>
                        </div>
                    </div>
                   
                    <div class="row">

                        <div class="col-md-6 d-flex flex-md-column-btn">
                            <div class="w-100 mt-2 mb-5 mt-20-btn">
                                <button mat-button class="strok-blue-btn btn" matStepperPrevious>Back</button>
                            </div>
                            <div class="w-100 mt-2 mb-5 ms-lg-3 mt-20-btn">

                                <button mat-flat-button class="blue-btn btn" matStepperNext
                                    [disabled]="registerCompanyForm.get('org_name').invalid || registerCompanyForm.get('org_contact_email_address').invalid || registerCompanyForm.get('org_contact_phone_number').invalid"
                                    (click)="companyRegisterForm()">SUBMIT</button>
                            </div>
                        </div>
                    </div>
                </mat-step>
            </mat-stepper>
        </form>
        <div class="done-page mt-5" *ngIf="isDone">
            <div class="d-flex flex-md-wrap">
                <div>
                    <img src="../../../../../../assets/images/done.png" alt="">
                </div>
                <div class="ms-3 col-md-7 responsive-side-text">
                    <h2>Done</h2>
                    <p>You have sucessfully added your business “{{registerCompanyForm.get('org_name').value}}”</p>
                    <div class="d-flex align-items-center responsive-row">
                        <button mat-button class="strok-blue-btn btn mb-3 "
                            (click)="navigateToDetailsPage(orgIdNew)">Check
                            out
                            company
                            profile</button>
                        <button mat-button class="blue-btn dashboard-btn btn mb-3 ms-lg-4"
                            (click)="navigateToDashboardPage(orgIdNew)">Back
                            to
                            Business Dashboard</button>
                    </div>
                </div>
            </div>

        </div>
    </div>
</div>