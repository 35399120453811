<div class="d-flex justify-content-between align-items-center">

    <p class="my-0 px-2" *ngIf="totalItems">
        Showing {{ startIndex + 1 }} - {{ endIndex }} of {{ totalItems }} Entries
    </p>

    <p class="my-0 px-2" *ngIf="!totalItems">
        Showing 0 - 0 of 0 Entries
    </p>

    <mat-paginator
    [length]="totalItems"
    [pageSize]="pageSize"
    [pageIndex]="pageIndex"
    [pageSizeOptions]="pageSizeOptions"
    (page)="pageChanged.emit($event)"
    aria-label="Select page"
    ></mat-paginator>

</div>