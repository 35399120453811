import { Component, ElementRef, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators, AbstractControl } from '@angular/forms';
import { Router } from '@angular/router';
import { AlertService } from 'src/app/shared/services/alert.service';
import { ApiCallMethodsService } from 'src/app/shared/services/api-call-methods.service';
import { AuthService } from '../../services/auth.service';
import { apiRoutes } from 'src/app/constants/apiRoutes';
import { UploadService } from 'src/app/shared/services/upload.service';
import { Country, State, City } from 'country-state-city';
import { CountdownConfig, CountdownEvent, CountdownComponent } from 'ngx-countdown';
import { stateCityData } from 'src/app/constants/state-city-json';

import * as moment from 'moment';

@Component({
  selector: 'app-sign-up',
  templateUrl: './sign-up.component.html',
  styleUrls: ['./sign-up.component.scss']
})
export class SignUpComponent {
  otp: any = null;
  @ViewChild('otpInput') otpInput: any;
  @ViewChild('otpCountdown', { static: false }) private countdown: CountdownComponent;

  isDisplayPassword: boolean = false;
  isDisplayConfirmPassword: boolean = false;
  currentStep = 0;
  signUpForm: FormGroup;
  otpField: boolean = false;
  mobileNumber: string = '';
  otpNumber: string;
  apiCallingNumber: any;
  verifingEmailaddress: boolean = false;
  otpConfiguration: any = {
    otpLength: 4,
    allowNumbersOnly: true,
    disableAutoFocus: true
  };
  verifiedOtp: boolean = false;
  cancelEmailVerification: boolean = false;
  cancelMobileVerification: boolean = false;
  mobileFieldDisable: boolean = false;
  @ViewChild('country') country: ElementRef;
  @ViewChild('city') city: ElementRef;
  @ViewChild('state') state: ElementRef;
  expiringTime: any;
  expiringTimeWithMoment: any;
  minutesTime: any;
  otpVerifyError: boolean = false;
  stateCityList = Object.keys(stateCityData).map(key => ({ key, cities: stateCityData[key] }));
  selectedStateCities: string[];
  selectedShippingStateCities: string[];
  showCountdown: boolean = true;

  //OTP VERIFY
  signUpTab: any = 1;
  otpValue: any = null;
  @ViewChild('otpInput') emailOTP: any;
  @ViewChild('otpForEmail', { static: false }) private otpForEmails: CountdownComponent;
  otpEmailValue: boolean = false;
  otpEmail: any;
  config = {
    allowNumbersOnly: false,
    length: 6,
    isPasswordInput: false,
    disableAutoFocus: false,
    otp: ''
  };
  otpEmailConfig: any = {
    otpLength: null,
    otp: null,
    allowNumbersOnly: true,
    disableAutoFocus: true,
  };
  countdownConfig: CountdownConfig = {
    leftTime: 60,
    format: 'ss',
  };
  signupMessage: any;
  showEmailCountdown: boolean = true;
  emailDummy: string;
  countdownDone: boolean = true;
  btnDisableOtp: boolean = false;
  errorOtpMessage: boolean = false;

  countryEdit: any;
  countries = Country.getAllCountries();
  countrySearch = ''; 
  filteredCountries = this.countries; 
  countryOptionSearch = ''; 
  filteredOptionCountries = this.countries; 

  constructor(
    private formBuilder: FormBuilder,
    private apiCallMethodsService: ApiCallMethodsService,
    private alertService: AlertService,
    private router: Router,
    private authService: AuthService,
    private uploadService: UploadService,
  ) {
    this.signUpForm = this.formBuilder.group({
      user_email_id: new FormControl('', Validators.compose([Validators.required, Validators.email])),
      user_name: new FormControl('', Validators.compose([Validators.required])),
      password: new FormControl('', Validators.compose([Validators.required, Validators.minLength(8), Validators.pattern(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/)])),
      user_dial_code: new FormControl('', Validators.compose([])),
      user_contact_mobile: new FormControl('', Validators.compose([Validators.required, Validators.pattern(/^\d{10}$/)])),
      user_addr_line2: new FormControl('', Validators.compose([])),
      user_addr_country: new FormControl('', Validators.compose([])),
      user_addr_city: new FormControl('', Validators.compose([])),
      user_addr_state: new FormControl('', Validators.compose([])),
      user_addr_pin: new FormControl('', Validators.compose([Validators.pattern(/^\d{6}$/)])),
      user_addr_line1: new FormControl('', Validators.compose([])),
      org_name: new FormControl('', Validators.compose([Validators.required])),
      org_type: new FormControl('', Validators.compose([])),
      org_gstin: new FormControl('', Validators.compose([])),
      org_addr_line2: new FormControl('', Validators.compose([])),
      org_country: new FormControl('', Validators.compose([])),
      org_addr_city: new FormControl('', Validators.compose([])),
      org_addr_state: new FormControl('', Validators.compose([])),
      org_addr_post_code: new FormControl('', Validators.compose([Validators.pattern(/^\d{6}$/)])),
      org_addr_line1: new FormControl('', Validators.compose([])),
      confirmPassword: new FormControl('', Validators.compose([Validators.required])),
      phone: new FormControl(undefined, [Validators.required])
    }, {
      validators: this.passwordsMatchValidator
    });
    if (this.otpInput) {
      this.otpInput.nativeElement.focus();
    }

    this.otp = new FormControl('', [Validators.required]);
    
    localStorage.removeItem('selectedCountry');
    localStorage.removeItem('selectedKinCountry');
    localStorage.removeItem('selectedConCountry');
  }

  passwordsMatchValidator(formGroup: FormGroup) {
    const passwordControl = formGroup.get('password');
    const confirmPasswordControl = formGroup.get('confirmPassword');

    if (confirmPasswordControl.errors && !confirmPasswordControl.errors['passwordNotMatch']) {
      // Don't overwrite an existing error
      return;
    }

    if (passwordControl.value !== confirmPasswordControl.value) {
      confirmPasswordControl.setErrors({ passwordNotMatch: true });
    } else {
      confirmPasswordControl.setErrors(null);
    }
  }


  showPassword() {
    this.isDisplayPassword = !this.isDisplayPassword;
  }
  showConfirmPassword() {
    this.isDisplayConfirmPassword = !this.isDisplayConfirmPassword;
  }
  uploadedLogo: any = null;
  uploadedLogoSrc: any = null;
  selectLogo(filesList: any) {
    let files = filesList.target.files;
    var fileTypes = ['jpg', 'jpeg', 'png'];
    var extension = files.item(0).name.split('.').pop().toLowerCase(),
      isSuccess = fileTypes.indexOf(extension) > -1;
    if (isSuccess) {
      var reader = new FileReader();
      const fileToUpload = files.item(0);
      this.uploadedLogo = files[0];
      reader.readAsDataURL(fileToUpload);
      reader.onload = () => {
        this.uploadedLogoSrc = reader.result as string;
        console.log(this.uploadedLogo);
      };
    } else {
      alert('Invalid Upload Type');
    }
  }
  isRadioBtnActive: boolean = false;
  isHomeDivActive: boolean = false;
  ischeck: boolean = false;
  toggleRadioBtn() {
    this.isRadioBtnActive = !this.isRadioBtnActive;
    this.ischeck = this.isRadioBtnActive;
  }
  toggleHomeDiv() {
    this.isHomeDivActive = !this.isHomeDivActive;
  }
  goToStep(step: number): void {
    this.currentStep = step;
  }
  wrongMobileNumber: any;
  onMobileNumberInput() {
    this.wrongMobileNumber = this.signUpForm.value.user_contact_mobile;
    if (this.signUpForm.value.user_contact_mobile.length === 10) {
      this.mobileFieldDisable = true;
      this.verifyNumber();
      //this.updateMobileFieldDisabledState();

    }

    if (this.wrongMobileNumber.trim() === '') {
      this.cancelMobileVerification = false;
    }
    //this.verifyNumber();
  }
  verifyEmail() {
    const emailFormControl = this.signUpForm.get('user_email_id');
    if (emailFormControl.value && emailFormControl.value.endsWith('.com') && emailFormControl.valid) {
      this.verificationEmail();

    }
  }
  verificationEmail() {
    let payload = {
      "email": this.signUpForm.value.user_email_id,
    }
    this.apiCallMethodsService.post(apiRoutes.checkEmailexists, payload).subscribe((response: any) => {
      if (response?.success == true) {
        this.verifingEmailaddress = true;
        this.cancelEmailVerification = false

      }
      if (response?.success == false) {
        this.cancelEmailVerification = true;
        this.verifingEmailaddress = false
      }

    },
      (error: any) => {
        this.alertService.failureAlert(error.error.message);
        // this.cancelEmailVerification = true;
      }
    )
  }
  onCountdownFinish() {
    this.showCountdown = false;
  }
  handleCountdown(e: CountdownEvent) {
    if (e.left == 0) {
      this.showCountdown = false;
    }
  }
  verifyNumber() {
    let payload = {
      "phone_number": this.signUpForm.value.user_contact_mobile,
    }

    this.apiCallMethodsService.post(apiRoutes.mobileVerify, payload).subscribe((response: any) => {
      if (response?.success == true) {
        this.otpField = true;
        this.showCountdown = true;
        this.cancelMobileVerification = false
      }
      this.expiringTime = response?.otp?.expiry_time;
      console.log(this.expiringTime);

      this.expiringTimeWithMoment = moment.utc(this.expiringTime);
      const currentTime = moment.utc();
      const timeDiff = this.expiringTimeWithMoment.diff(currentTime);

      // Format the duration in minutes and seconds
      const remainingTime = moment.duration(timeDiff);
      const minutes = Math.floor(remainingTime.asMinutes());
      const seconds = remainingTime.seconds();
      this.minutesTime = {
        leftTime: {
          minute: minutes,
          second: seconds
        }
      };      // Display remaining time in minutes and seconds
      if (response?.success == false) {
        this.cancelMobileVerification = true;
        this.mobileFieldDisable = false;
        //  this.otpField = false;
      }
      console.log(`Remaining time for OTP count: ${minutes}:${seconds}`);
      // this.expiringTimeWithMoment = moment.utc(this.expiringTime);
      // console.log(this.expiringTimeWithMoment);      console.log(this.expiringTimeWithMoment);

    },
      (error: any) => {
        this.alertService.failureAlert(error.error.message);
      })
  };

  resendotp() {
    this.onMobileNumberInput();
    if (this.showCountdown) {
      setTimeout(() => {
        this.showCountdown = false;
        console.log(this.showCountdown);

      }, 100);
    };
  }

  onOtpInputChange(value: string): void {
    // const mobileNumberWithoutPrefix = this.signUpForm.value.user_contact_mobile.substring(3);

    if (value) {
      let payload = {
        "phone_number": this.signUpForm.value.user_contact_mobile,
        "otp": value,
      };

      this.apiCallMethodsService.post(apiRoutes.generateOTP, payload).subscribe((response: any) => {
        console.log(response);
        if (response?.success == true) {
          this.otpField = false;
          this.verifiedOtp = true;
        }
        if (response?.success == false) {
          this.otpField = true;
          this.verifiedOtp = false;
          this.otpVerifyError = true;
        }
      },
        (error: any) => {
          if (error?.success == false) {
            this.otpField = true;
            this.verifiedOtp = false;

          }
          this.alertService.failureAlert(error.error.message);
        });
    }
    // this.verifiedOtp = true;
  }

  submitSignUp() {
    this.btnDisableOtp = true;

    if (this.uploadedLogo) {
      this.uploadService.uploadToS3(this.uploadedLogo, 'logo').promise().then((response: any) => {
        this.signUpForm.value['org_image_link'] = response.Location;
        console.log(this.signUpForm.value['org_image_link']);

      }).catch((error: any) => {

      })
    }
 
    
    this.signUpForm.value.user_addr_country = this.selectedCountry?.name;
    this.signUpForm.value.user_addr_state = this.selectedState?.name;
    this.signUpForm.value.user_addr_city = this.selectedCity?.name;

    this.signUpForm.value.org_country = this.selectedOptionCountry?.name;
    this.signUpForm.value.org_addr_state = this.selectedOptionState?.name;
    this.signUpForm.value.org_addr_city = this.selectedOptionCity?.name;

    const data = this.signUpForm.value;
    const payload = {
      user_data: {
        password: data.password,
        user_email_id: data.user_email_id,
        user_name: data.user_name,
        user_dial_code: data.user_dial_code,
        user_contact_mobile: data.user_dial_code + this.signUpForm.value.user_contact_mobile,
        user_addr_line1: data.user_addr_line1,
        user_addr_line2: data.user_addr_line2,
        user_addr_country: data.user_addr_country,
        user_addr_city: data.user_addr_city,
        user_addr_state: data.user_addr_state,
        user_addr_pin: data.user_addr_pin,
      },
      org_name: data.org_name,
      org_type: data.org_type,
      org_gstin: data.org_gstin,
      org_addr_line1: data.org_addr_line1,
      org_addr_line2: data.org_addr_line2,
      org_country: data.org_country,
      org_addr_city: data.org_addr_city,
      org_addr_state: data.org_addr_state,
      org_addr_post_code: data.org_addr_post_code,
      org_image_link: data.org_image_link,
    };


    setTimeout(() => {
      this.apiCallMethodsService.post(apiRoutes.registration, payload).subscribe((response: any) => {
        if (response?.success == true) {
          // this.alertService.successAlert(response?.message);
          this.btnDisableOtp = false;
            this.signUpTab = 2;
            // this.authService.setToken(response);
            // this.router.navigate(['admin/business-management/select-company']);
        }
      },
        (error: any) => {
          this.btnDisableOtp = false;
          this.alertService.failureAlert(error.error.message);
        })

    }, 2000)
  }

  submitOTP() {
    this.btnDisableOtp = true;

    if (this.uploadedLogo) {
      this.uploadService.uploadToS3(this.uploadedLogo, 'logo').promise().then((response: any) => {
        this.signUpForm.value['org_image_link'] = response.Location;
        console.log(this.signUpForm.value['org_image_link']);

      }).catch((error: any) => {

      })
    }

    this.signUpForm.value.user_addr_country = this.selectedCountry?.name;
    this.signUpForm.value.user_addr_state = this.selectedState?.name;
    this.signUpForm.value.user_addr_city = this.selectedCity?.name;

    this.signUpForm.value.org_country = this.selectedOptionCountry?.name;
    this.signUpForm.value.org_addr_state = this.selectedOptionState?.name;
    this.signUpForm.value.org_addr_city = this.selectedOptionCity?.name;

    const data = this.signUpForm.value;
    const payload = {
      user_data: {
        password: data.password,
        user_email_id: data.user_email_id,
        user_name: data.user_name,
        user_dial_code: data.user_dial_code,
        user_contact_mobile: data.user_dial_code + this.signUpForm.value.user_contact_mobile,
        user_addr_line1: data.user_addr_line1,
        user_addr_line2: data.user_addr_line2,
        user_addr_country: data.user_addr_country,
        user_addr_city: data.user_addr_city,
        user_addr_state: data.user_addr_state,
        user_addr_pin: data.user_addr_pin,
      },
      org_name: data.org_name,
      org_type: data.org_type,
      org_gstin: data.org_gstin,
      org_addr_line1: data.org_addr_line1,
      org_addr_line2: data.org_addr_line2,
      org_country: data.org_country,
      org_addr_city: data.org_addr_city,
      org_addr_state: data.org_addr_state,
      org_addr_post_code: data.org_addr_post_code,
      org_image_link: data.org_image_link,
      otp: this.otp.value
    };

    console.log(payload);

    this.apiCallMethodsService.post(apiRoutes.registration, payload).subscribe((response: any) => {
      this.signupMessage = response;
      if (this.signupMessage?.success == true) {
        
        this.alertService.successAlert(response?.message);
        setTimeout(() => {
          this.btnDisableOtp = false;
          this.authService.removeId();
          this.authService.setToken(response);

          // this.router.navigate(['admin/business-management/select-company']);
          this.router.navigate(['/select-company']);
        }, 2000)
      };
    }, (error: any) => {
        //  this.alertService.failureAlert(error.error.message);
         this.btnDisableOtp = false;
         this.errorOtpMessage = true;

    });
  }

  resendEmailOtp() {
    this.btnDisableOtp = true;

    let payload = {
      "email": this.signUpForm.value.user_email_id,
    }
    this.apiCallMethodsService.post(apiRoutes.resendLoginOTP, payload).subscribe((response: any) => {
      if (response?.success == true) {
        // this.alertService.successAlert(response?.message);
          this.countdownDone = true;
          this.btnDisableOtp = false;

          this.configureOtpAndCountdown()
      };
    }, (error: any) => {
      //  this.alertService.failureAlert(error.error.message);
      this.btnDisableOtp = false;
      this.errorOtpMessage = true;
    });

  }

  handleEmailCountdown(e: CountdownEvent) {
    if (e.left == 0) {
      this.countdownDone = false;
      console.log(this.countdownDone);

    }
  }

  phoneNumberValidation(event) {
    if (event.target.value.length >= 10) {
      return false;
    }
    return true;
  }
  // onStateChange() {
  //   this.selectedStateCities = stateCityData[this.signUpForm.value.user_addr_state];
  // }
  onStateChanges() {
    this.selectedShippingStateCities = stateCityData[this.signUpForm.value.org_addr_state];
  }
  updateMobileFieldDisabledState() {
    const mobileFormControl = this.signUpForm.get('user_contact_mobile');

    if (!!mobileFormControl.value && mobileFormControl.value.length === 10) {
      mobileFormControl.disable();
    } else {
      mobileFormControl.enable();
    }
  }


  configureOtpAndCountdown() {
    this.otpConfiguration.length = 6;
    this.countdownConfig.leftTime = 60;
  }

  

states = null;
cities = null;

selectedCountry;
selectedState;
selectedCity;

filterCountries() {
  const searchValue = this.countrySearch.toLowerCase();
  this.filteredCountries = this.countries.filter(country =>
    country.name.toLowerCase().includes(searchValue)
  );
}

onCountryChange($event: any): void {
  const selectedCountry = $event.value;
  this.states = State.getStatesOfCountry(selectedCountry.isoCode); 

  console.log(this.states); 
  this.selectedCountry = selectedCountry; 
  this.cities = this.selectedState = this.selectedCity = null;

  this.signUpForm.patchValue({
    user_addr_state: null,
    user_addr_city: null
  });

  this.countrySearch = null;

}


onStateChange($event: any): void {
  const selectedState = $event.value;

  if (this.selectedCountry) {
    this.cities = City.getCitiesOfState(this.selectedCountry.isoCode, selectedState.isoCode);
  }
  this.selectedState = selectedState; 
  this.selectedCity = null;
  this.signUpForm.value.user_addr_city = "";
}

onCityChange($event: any): void {
  this.selectedCity = $event.value; // Get the selected city directly from event value
}

  // OPTIONAL

  optionStates = null;
  optionCities = null;
  
  selectedOptionCountry;
  selectedOptionState;
  selectedOptionCity;
  
  
filterOptionalCountries() {
  const searchValue = this.countryOptionSearch.toLowerCase();
  this.filteredOptionCountries = this.countries.filter(country =>
    country.name.toLowerCase().includes(searchValue)
  );
}
  
  onOptionalCountryChange($event: any): void {
    const selectedCountry = $event.value;
    this.optionStates = State.getStatesOfCountry(selectedCountry.isoCode); 
  
    console.log(this.states); 
    this.selectedOptionCountry = selectedCountry; 
    this.optionCities = this.selectedOptionState = this.selectedOptionCity = null;

    this.signUpForm.patchValue({
      org_addr_state: null,
      org_addr_city: null
    });
  
    this.countryOptionSearch = null;
    }
  
  
  onOptionalStateChange($event: any): void {
    const selectedState = $event.value;
  
    if (this.selectedOptionCountry) {
      this.optionCities = City.getCitiesOfState(this.selectedOptionCountry.isoCode, selectedState.isoCode);
    }
    this.selectedOptionState = selectedState; 
    this.selectedOptionCity = null;
    this.signUpForm.value.org_addr_city = "";
  }
  
  onOptionalCityChange($event: any): void {
    this.selectedOptionCity = $event.value; // Get the selected city directly from event value
  }

  
  selectCountry(event) {
    console.log(event);
    
    this.signUpForm.patchValue({
      user_dial_code: "+" + event
    });
  };


  
stopPropagation(event: Event): void {
  event.stopPropagation();
}
}
