import { Component, ElementRef, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators, AbstractControl } from '@angular/forms';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/authentication/services/auth.service';
import { apiRoutes } from 'src/app/constants/apiRoutes';
import { AlertService } from 'src/app/shared/services/alert.service';
import { ApiCallMethodsService } from 'src/app/shared/services/api-call-methods.service';
import { UploadService } from 'src/app/shared/services/upload.service';
import { stateCityData } from 'src/app/constants/state-city-json';
import { Country, State, City }  from 'country-state-city';

@Component({
  selector: 'app-company-register',
  templateUrl: './company-register.component.html',
  styleUrls: ['./company-register.component.scss']
})
export class CompanyRegisterComponent {
  @ViewChild('country') country: ElementRef;
  @ViewChild('city') city: ElementRef;
  @ViewChild('state') state: ElementRef;
  isRadioBtnActive: boolean = false;
  isHomeDivActive: boolean = false;
  ischeck: boolean = false;
  isDone: boolean = false;
  registerCompanyForm: FormGroup;
  userDetails: any;
  orgIdNew: any;
  accountNumber: any = null;
  accountType: any = null;
  bankName: any = null;
  branchName: any = null;
  ifscCode: any = null;
  selectedStateCities: string[];
  selectedShippingStateCities: string[];
  stateCityList = Object.keys(stateCityData).map(key => ({ key, cities: stateCityData[key] }));
  imgLink: any;

  bankDetails: { org_id: any; bank_account_number: any; account_type: any; bank: any; branch_name: any; ifsc_code: any; default: true };
  countries = Country.getAllCountries();
  countrySearch = ''; 
  filteredCountries = this.countries; 
  oldOrgId: any = null;
  constructor(
    private formBuilder: FormBuilder,
    private apiCallMethodsService: ApiCallMethodsService,
    private alertService: AlertService,
    private router: Router,
    private authService: AuthService,
    private uploadService: UploadService,
  ) {
    this.userDetails = this.authService.getSessionDetail();
    this.oldOrgId = this.authService.getId();
    console.log(this.oldOrgId);
    
    this.registerCompanyForm = this.formBuilder.group({
      org_name: new FormControl('', Validators.compose([Validators.required])),
      org_type: new FormControl('', Validators.compose([])),
      org_gstin: new FormControl('', Validators.compose([])),
      org_addr_line1: new FormControl('', Validators.compose([])),
      org_addr_line2: new FormControl('', Validators.compose([])),
      org_addr_country: new FormControl('', Validators.compose([])),
      org_addr_state: new FormControl('', Validators.compose([])),
      org_addr_city: new FormControl('', Validators.compose([])),
      org_addr_post_code: new FormControl('', Validators.compose([Validators.pattern(/^\d{6}$/)])),
      dial_code: new FormControl('', Validators.compose([])),
      org_contact_phone_number: new FormControl('', Validators.compose([Validators.required, Validators.pattern('^[0-9]{10}$')])),
      org_contact_email_address: new FormControl('', Validators.compose([Validators.required, Validators.email])),
      user_id:this.userDetails?.user?.user_id,
      pan: new FormControl('', Validators.compose([Validators.pattern(/^[A-Z]{5}[0-9]{4}[A-Z]$/)])),
      tin_number: new FormControl('', Validators.compose([ Validators.pattern(/^(https?:\/\/)?([a-zA-Z0-9-]+\.)+[a-zA-Z]{2,6}(:\d+)?(\/.*)?$/)])),
      org_website: new FormControl('', Validators.compose([])),
      org_business_name: new FormControl('', Validators.compose([])),
    });


      
    localStorage.removeItem('selectedCountry');
    localStorage.removeItem('selectedKinCountry');
    localStorage.removeItem('selectedConCountry');
  };
  
  // onStateChange() {
  //   this.selectedStateCities = stateCityData[this.registerCompanyForm.value.org_addr_state];
  //   this.registerCompanyForm.value.org_addr_city = "";
  // }
  // for image send in AWS
  uploadedLogo: any = null;
  uploadedLogoSrc: any = null;
  selectLogo(filesList: any) {
    let files = filesList.target.files;
    var fileTypes = ['jpg', 'jpeg', 'png'];
    var extension = files.item(0).name.split('.').pop().toLowerCase(),
      isSuccess = fileTypes.indexOf(extension) > -1;
    if (isSuccess) {
      var reader = new FileReader();
      const fileToUpload = files.item(0);
      this.uploadedLogo = files[0];
      reader.readAsDataURL(fileToUpload);
      reader.onload = () => {
        this.uploadedLogoSrc = reader.result as string;
        console.log(this.uploadedLogo);
      };
    } else {
      alert('Invalid Upload Type');
    }
  }

  companyRegisterForm() {
    if (this.uploadedLogo) {
      this.uploadService.uploadToS3(this.uploadedLogo, 'logo').promise().then((response: any) => {
        this.registerCompanyForm.value['org_image_link'] = response.Location;
        console.log(this.registerCompanyForm.value['org_image_link']);
        data.org_image_link = response.Location; // Update the form data
        this.imgLink=  this.registerCompanyForm.value['org_image_link'];

      }).catch((error: any) => {

      })
    };
    this.registerCompanyForm.value.org_addr_country = this.selectedCountry?.name;
    this.registerCompanyForm.value.org_addr_state = this.selectedState?.name;
    this.registerCompanyForm.value.org_addr_city = this.selectedCity?.name;

    const data = this.registerCompanyForm.value;

    if (this.accountNumber || this.registerCompanyForm.value.account_type || this.registerCompanyForm.value.bank || this.registerCompanyForm.value.branch_name || this.registerCompanyForm.value.ifsc_code) {
      this.bankDetails = {
        org_id: this.userDetails?.user?.org_id,
        bank_account_number: this.accountNumber,
        account_type: this.accountType,
        bank: this.bankName,
        branch_name: this.branchName,
        ifsc_code: this.ifscCode,
        default: true,
      };
    } else{
    // this.registerCompanyForm.removeControl('account_type');
    // this.registerCompanyForm.removeControl('bank_account_number');
    this.accountNumber = null;
    // this.registerCompanyForm.removeControl('bank');
    // this.registerCompanyForm.removeControl('branch_name');
    // this.registerCompanyForm.removeControl('ifsc_code');
    }
    this.registerCompanyForm.value.org_bank_details = this.bankDetails ? [this.bankDetails] : []
    console.log(this.registerCompanyForm.value);
    
    setTimeout(() => {
      this.apiCallMethodsService.post(apiRoutes.addOrgProfile, this.registerCompanyForm.value).subscribe((response: any) => {
        if (response?.success == true) {
          this.isDone = true;
          this.orgIdNew = response?.org_id;
        }
      })
    }, 2500)
  }
  navigateToDetailsPage(userId: any) {
    this.router.navigate(['/admin/setting-details/profile'], { queryParams: { id: userId } });
    console.log(this.orgIdNew);
    
    this.authService.setId(userId);
  }
  navigateToDashboardPage(userId: any) {
    this.authService.setId(userId);
    
    this.router.navigate(['/admin/dashboard'], { queryParams: { id: userId } });

  }
  toggleRadioBtn() {
    this.isRadioBtnActive = !this.isRadioBtnActive;
    this.ischeck = this.isRadioBtnActive;
  }
  submitForm() {
    this.isDone = true;
  }
  toggleHomeDiv() {
    this.isHomeDivActive = !this.isHomeDivActive;
  }

  

states = null;
cities = null;

selectedCountry;
selectedState;
selectedCity;

filterCountries() {
  const searchValue = this.countrySearch.toLowerCase();
  this.filteredCountries = this.countries.filter(country =>
    country.name.toLowerCase().includes(searchValue)
  );
}

onCountryChange($event: any): void {
  const selectedCountry = $event.value;
  this.states = State.getStatesOfCountry(selectedCountry.isoCode); 

  console.log(this.states); 
  this.selectedCountry = selectedCountry; 
  this.cities = this.selectedState = this.selectedCity = null;

  this.registerCompanyForm.patchValue({
    org_addr_state: null,
    org_addr_city: null
  });
  this.countrySearch = null;
}


onStateChange($event: any): void {
  const selectedState = $event.value;

  if (this.selectedCountry) {
    this.cities = City.getCitiesOfState(this.selectedCountry.isoCode, selectedState.isoCode);
  }
  this.selectedState = selectedState; 
  this.selectedCity = null;
  this.registerCompanyForm.value.org_addr_city = "";
}

onCityChange($event: any): void {
  this.selectedCity = $event.value; // Get the selected city directly from event value
}
preventEnter(event: KeyboardEvent) {
  event.preventDefault();
}
stopPropagation(event: Event): void {
  event.stopPropagation();
}
selectCountry(event) {
  console.log(event);
  
  this.registerCompanyForm.patchValue({
    dial_code: "+" + event
  });
};

}
