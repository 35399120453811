import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { apiRoutes } from 'src/app/constants/apiRoutes';
import { validationErrorMessages } from 'src/app/constants/validation-error-messages';
import { AlertService } from 'src/app/shared/services/alert.service';
import { ApiCallMethodsService } from 'src/app/shared/services/api-call-methods.service';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss']
})
export class ForgotPasswordComponent implements OnInit {
  forgotPasswordForm: FormGroup;
  validationErrorMessages:any = validationErrorMessages;
  selectedCountryCode = '+91';

  constructor(private formBuilder: FormBuilder,
               private router:Router,
               private apiCallMethodsService : ApiCallMethodsService,
              private alertService : AlertService,
              private _snackBar: MatSnackBar) {
    this.forgotPasswordForm = this.formBuilder.group({
      phoneNumber: new FormControl('',Validators.compose([Validators.required])),
    });
   }

   validatePhoneNumber(event){
    const charCode = (event.which) ? event.which : event.keyCode;
    if(event.target.value.length >= 10 || charCode > 31 && (charCode < 48 || charCode > 57)){
      return false;
    }
    return true;
   }

   submitForgotPassword()
   {
      if(this.forgotPasswordForm.valid){

        this.forgotPasswordForm.value['dialCode'] = this.selectedCountryCode;
        this.forgotPasswordForm.value['next'] = 'admin/dashboard';

        console.log(this.forgotPasswordForm.value);

        this.apiCallMethodsService.post(apiRoutes.forgotPassword,this.forgotPasswordForm.value).subscribe((response:any)=>{

          if(response.succeeded == true)
          {
            let otpDetail = null;
            otpDetail = response.data;
            this.alertService.successAlert(response.message[0]);
            setTimeout(()=>{
            this.router.navigate(['verify-otp',this.forgotPasswordForm.value.dialCode,this.forgotPasswordForm.value.phoneNumber,otpDetail.otpLength,otpDetail.requestId,otpDetail.otpResendIntervalSeconds,otpDetail.resendRemains,'forgot']);
            this._snackBar.open(`Otp: ${otpDetail?.otp}`,'CLOSE');
            },2000)
          }

      },(error:any)=>{
        this.alertService.failureAlert(error.error.message);
      })

      }
   }

   isInvalid(formControlName:any)
   {
      let result:boolean = false;
      for(let i = 0; i <  this.validationErrorMessages[formControlName].length; i++)
      {
          if(this.forgotPasswordForm?.get(formControlName)?.hasError(this.validationErrorMessages[formControlName][i].type) && (this.forgotPasswordForm?.get(formControlName)?.dirty ||  this.forgotPasswordForm?.get(formControlName)?.touched))
          {
              result = true;
          }
      }
      return result;
   }

  ngOnInit(): void {
  }

}
