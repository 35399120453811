<form [formGroup]="createPasswordForm">
    <div class="reset-password login-page mb-4">
        <div class="container">
            <div class="width-container mx-auto">
                <div class="d-flex align-items-center justify-content-center pt-2 w-100">
                    <img src="../../../../assets/images/logo-cloud-balance.png" alt="" class="mt-3 cb-logo">
                </div>
                <h2 class="text-center reset-heading">Create New Password</h2>
                <div class="d-flex align-items-center justify-content-center">

                    <p class="">Enter the email or mobile number associated
                        with your account and we’ll send an email/sms with
                        instructions to reset your password.</p>
                </div>
                <div>
                    <mat-form-field class="example-full-width input-style create-password w-100" appearance="outline">
                        <mat-label>Create Password*</mat-label>
                        <input matInput [type]="isDisplayPassword ? 'text' : 'password'" formControlName="newPassword">
                        <mat-icon matSuffix (click)="showPassword()" class="cursor-pointer">{{isDisplayPassword ?
                            'visibility' :
                            'visibility_off' }}</mat-icon>
                        <mat-error *ngIf="createPasswordForm.get('newPassword').hasError('minlength')">
                            <div class="d-flex align-items-center">
                                <img src="../../../../assets/images/cautiom.svg" alt="" class="me-1">
                                <span class="caution">Password must be at least 8 characters
                                    long with special character</span>
                            </div>
                        </mat-error>

                    </mat-form-field>

                    <div class="d-flex align-items-center info mb-3 mt-2">
                        <mat-icon>info</mat-icon>
                        <h5 class="mb-0 ms-1 small">
                            Password must be atleast 8 character long  with special character
                        </h5>
                    </div>
                </div>

                <div class="mt-3">
                    <mat-form-field class="example-full-width input-style w-100" appearance="outline">
                        <mat-label>Confirm Password*</mat-label>
                        <input matInput formControlName="confirm_pass"
                            [type]="isDisplayConfirmPassword ? 'text' : 'password'">
                        <mat-icon matSuffix (click)="showConfirmPassword()"
                            class="cursor-pointer">{{isDisplayConfirmPassword ?
                            'visibility' :
                            'visibility_off' }}</mat-icon>
                        <mat-error *ngIf="createPasswordForm.get('confirm_pass').hasError('passwordNotMatch')">
                            <div class="d-flex align-items-center">
                                <img src="../../../../assets/images/caution.svg" alt="" class="me-1">
                                <div class="d-flex align-items-center">
                                    <img src="../../../../assets/images/cautiom.svg" alt="" class="me-1">
                                    <span class="caution">Password not matched</span>
                                </div>
                            </div>
                        </mat-error>

                    </mat-form-field>

                </div>
          
                <div>
                    <button mat-button class="blue-btn btn mt-5 mb-5" [disabled]="createPasswordForm.invalid" (click)="resetPassword()">RESET PASSWORD</button>
                </div>
            </div>
        </div>
    </div>
</form>