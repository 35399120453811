import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpErrorResponse
} from '@angular/common/http';
import { AuthService } from 'src/app/authentication/services/auth.service';
import { Router } from '@angular/router';
import { NEVER, Observable, throwError, switchMap } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ApiCallMethodsService } from '../services/api-call-methods.service';
import { apiRoutes } from 'src/app/constants/apiRoutes';

@Injectable()
export class HttpTokenInterceptor implements HttpInterceptor {

  constructor(private authService: AuthService, private router: Router, private modalService: NgbModal, private apiCallMethodsService: ApiCallMethodsService) { }

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (this.authService.isAuthenticated()) {
      request = this.addToken(request, this.authService.getToken());
    }
    else {
      request = this.addToken(request, null);
    }

    return next.handle(request).pipe(
      catchError((error) => {
        if (error instanceof HttpErrorResponse && error.status === 401) {
          return this.handle401Error(request, next, error);
        } else {
          return throwError(error);
        }
      })
    );


  }

  private addToken(
    request: HttpRequest<any>,
    token: string = null,
  ): HttpRequest<any> {
    let headers = {};

    if (token !== null) {
      headers['Authorization'] = `Bearer ${token}`;
    }

    return request.clone({
      setHeaders: headers,
    });
  }

  private handle401Error(request: HttpRequest<any>, next: HttpHandler, error: any): Observable<any> {
    var sessionDetail = this.authService.getSessionDetail();
    var newSessionDetail = JSON.parse(JSON.stringify(sessionDetail))
    if (sessionDetail) {
      var refreshTokenPayload = {
        "refreshToken": sessionDetail?.token?.refreshToken,
      };
      console.log('000 h');
        this.authService.removeToken();
        this.authService.removeId();
        this.router.navigateByUrl('/login');

        
      return this.apiCallMethodsService.post(apiRoutes.refreshToken, refreshTokenPayload).pipe(switchMap((response: any) => {
        newSessionDetail = newSessionDetail ? newSessionDetail : {};
        newSessionDetail['token']['token'] = response?.token?.token;
        newSessionDetail['token']['refreshToken'] = response?.token?.refreshToken;
        console.log(newSessionDetail)

        console.log('111 h');
        
        this.authService.removeToken();

        this.authService.setToken(newSessionDetail);
        console.log(this.authService.getToken());

        return next.handle(this.addToken(request, this.authService.getToken()));

      }), catchError((err) => {
        console.log(err);
        if (err?.token?.token) {
          newSessionDetail = newSessionDetail ? newSessionDetail : {};
          console.log('222 h');

          newSessionDetail['token']['token'] = err?.token?.token;
          newSessionDetail['token']['refreshToken'] = err?.token?.refreshToken;
          this.authService.removeToken();
          this.authService.removeId();
          this.router.navigateByUrl('/login');
          
          this.authService.setToken(newSessionDetail);
          
          console.log(this.authService.getToken());
          return next.handle(this.addToken(request, this.authService.getToken()));
        }
        else {
          console.log('333 h');

          this.authService.removeToken();
          this.authService.removeId();
          this.router.navigateByUrl('/login');
          return throwError(() => err);
        }

      }))
    }
    else {
      console.log('444 h');

      this.modalService.dismissAll();
      this.authService.removeToken();
      this.authService.removeId();
      this.router.navigateByUrl('/login');
      console.log('else ');

      return throwError(error);
    }

  }
  // {
  //   // let sessionDetail = this.authService.getSessionDetail();
  //   // if(sessionDetail){
  //   //   return this.apiCallMethodsService.get(apiRoutes.refreshToken).pipe(switchMap((response: any)=>{
  //   //     if(response.succeeded == true)
  //   //     {
  //   //       this.authService.setToken(response);
  //   //       // return next.handle(this.addToken(request, this.authService.getToken(),'',''));
  //   //       return next.handle(this.addToken(request, this.authService.getToken()));
  //   //     }
  //   //   }), catchError((err) => {
  //   //     return throwError(() => err);
  //   //   }))
  //   // }
  //   // else{
  //   //   this.modalService.dismissAll();
  //   //   this.router.navigateByUrl('/login');
  //   //   this.authService.removeToken();
  //   //   return throwError(error);
  //   // }
  //   //refresh token

  // }

}
