<div class="date-range">
  
    <button class="d-flex w-100 justify-content-between align-items-center" [matMenuTriggerFor]="menu" matRipple >
      <div>
        <p class="my-0">{{selectedFilter?.text}}</p>
        <h6 class="my-0">{{selectedFilter?.type ? selectedFilter?.type : 'Select Filter'}}</h6>
      </div>
      <mat-icon>arrow_drop_down</mat-icon>
    </button>

    <mat-menu #menu="matMenu">
      <div class="first-day">
        <p mat-menu-item (click)="selectFilter(1)">First 24 hours</p>
      </div>
      <div class="days-options">
        <p mat-menu-item (click)="selectFilter(2)">Last 7 days</p>
        <p mat-menu-item (click)="selectFilter(3)">Last 28 days</p>
        <p mat-menu-item (click)="selectFilter(4)">Last 90 days</p>
        <p mat-menu-item (click)="selectFilter(5)">Last 365 days</p>
        <p mat-menu-item (click)="selectFilter(6)">Lifetime</p>
      </div>
      <div class="years-options">
        <p mat-menu-item (click)="selectFilter('Year',currentYear)">{{currentYear}}</p>
        <p mat-menu-item (click)="selectFilter('Year',currentYear - 1)">{{currentYear - 1}}</p>
      </div>
      <div class="months-options">
        <p mat-menu-item *ngFor="let month of months" (click)="selectFilter('Month',month)">{{month}}</p>
      </div>
      <div class="custom-date">
        <p mat-menu-item (click)="rangePicker.open()">Custom</p>
      </div>
    </mat-menu>

    <mat-form-field appearance="outline" class="date-range-picker" hidden>
      <mat-date-range-input [rangePicker]="rangePicker">
        <input matStartDate [(ngModel)]="transactionStartDate">
        <input matEndDate [(ngModel)]="transactionEndDate">
      </mat-date-range-input>
      <mat-date-range-picker touchUi  #rangePicker>
        <mat-date-range-picker-actions>
          <button mat-button matDateRangePickerCancel>Cancel</button>
          <button mat-raised-button color="primary" matDateRangePickerApply (click)="selectFilter('Custom',{start: transactionStartDate, end: transactionEndDate})">Apply</button>
        </mat-date-range-picker-actions>
      </mat-date-range-picker>
    </mat-form-field>

  </div>