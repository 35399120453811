import { Component, EventEmitter, Input, Output, SimpleChanges } from '@angular/core';
import { countries } from 'src/app/constants/countryCodeData';

@Component({
  selector: 'app-another-int-tel-picker',
  templateUrl: './another-int-tel-picker.component.html',
  styleUrls: ['./another-int-tel-picker.component.scss']
})
export class AnotherIntTelPickerComponent {

  countriesList: any = null;
  selectedCountry: any = null;
  selectedKinCountry: any = null;
  isInitialized: boolean = false;
  searchTerm: string = '';
  filteredCountriesList: any[] = [];

  @Output() countryKinChange: EventEmitter<any> = new EventEmitter<any>();
  @Input() countryEdit: any;

  constructor() {

    this.countriesList = countries;

    this.countriesList.forEach(country => {

      country['flagUrl'] = 'https://flagcdn.com/64x48/' + country.iso.toLowerCase() + '.png';

    });

  }

  selectKinCountry(country) {

    this.selectedCountry = country;
    console.log(this.selectedCountry);
    localStorage.setItem('selectedKinCountry', JSON.stringify(country));

    this.countryKinChange.emit(this.selectedCountry?.code);
  }

  ngOnInit() {
    // let preSelectedCountry = this.countriesList.find(x=> x.country == 'India');
    // this.selectKinCountry(preSelectedCountry);

    const savedCountry = localStorage.getItem('selectedKinCountry');

    if (savedCountry) {

      this.selectedCountry = JSON.parse(savedCountry);
      console.log(this.selectedCountry);

    } else {

      let preSelectedCountry = this.countriesList.find(x => x.country == 'India');
      this.selectKinCountry(preSelectedCountry);
      console.log(preSelectedCountry);
      console.log(savedCountry);

    }

    this.isInitialized = true;
    // this.filteredCountriesList = this.countriesList; // Initialize filtered list

  }
  filterCountries() {
    this.filteredCountriesList = this.countriesList.filter(country =>
      country.country.toLowerCase().includes(this.searchTerm.toLowerCase()) ||
      country.code.includes(this.searchTerm)
    );
  }
  ngOnChanges(changes: SimpleChanges) {
    setTimeout(() => {
      
      if (changes['countryEdit'] && this.countryEdit && this.isInitialized) {
        const dialCode = this.countryEdit['alt_dial_code'];
        const country = this.countriesList.find(c => c.code === dialCode?.substring(1));
  
        if (dialCode == null) {
          let preSelectedCountry = this.countriesList.find(x => x.country == 'India');
          this.selectKinCountry(preSelectedCountry);
        } else{
  
          this.selectKinCountry(country);
        }
  
      }
    }, 100);
  }
  stopPropagation(event: Event): void {
    event.stopPropagation();
  }
}
