<div
    [ngClass]="{'blank-page': router.url === '/login' ||  router.url === '/auth/signup' || router.url === '/auth/reset-password' || router.url === '/create-password'  ||  router.url === '/verify-otp' ||  router.url === '/select-company' ||  router.url === '/company-register' || isBlankPage(router.url) || !showSidemenu}">
    <app-side-menu *ngIf="authService.isAuthenticated() && authService.isAuthenticatedId() && showSidemenu"
        [ngClass]="{'w-100' : showSidemenu == false}"></app-side-menu>
    <div class="main-content d-flex flex-column" [ngClass]="{'active': isToggled}"
        [class.hide-sidebar]="themeService.isHideSidebar()"
        [ngClass]="{'w-100' : authService.isAuthenticated() && authService.isAuthenticatedId() == false}">
        <div class="" [ngClass]="{'dashboard-content': authService.isAuthenticated()}">
            <app-header *ngIf="authService.isAuthenticatedId() && authService.isAuthenticated()"></app-header>

            <router-outlet></router-outlet>
        </div>
        <div class="flex-grow-1"></div>
        <app-footer *ngIf="authService.isAuthenticated()"></app-footer>
    </div>
</div>