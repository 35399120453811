import { Injectable } from '@angular/core';
import * as AWS from 'aws-sdk/global';
import * as S3 from 'aws-sdk/clients/s3';
@Injectable({
  providedIn: 'root'
})
export class UploadService {

  constructor() { }

  uploadToS3(file: any,folderName:any) {
    const uniqueId: number = Math.floor(Math.random() * Date.now());
    const file_name: string = folderName+'/'+uniqueId + "_" + file.name ;
    const contentType = file.type;
    console.log(contentType);
    const bucket = new S3(
      {
        accessKeyId: 'AKIAVKB7K4SW6QDOJJNE',
        secretAccessKey: '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',
        region: 'ap-south-1'
      }
    );
    const params = {
      Bucket: 'tradenext-upload-dev',
      Key: file_name,
      Body: file,
      ACL: 'public-read',
      ContentType: contentType
    };
    //----------Working Code 100% with returning the data--------------
    return bucket.upload(params, function (err:any, data:any) {
      if (err) {
        console.log('There was an error uploading your file: ', err);
        localStorage.setItem('docDataStatus', 'false');
        return false;

      }
      else {
        console.log('Successfully uploaded file from service');
        return true;
      }
    });
  }
}

