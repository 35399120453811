import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HeaderComponent } from './components/header/header.component';
import { SideMenuComponent } from './components/side-menu/side-menu.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { RouterModule } from '@angular/router';
import {MatSidenavModule} from '@angular/material/sidenav';
import {MatListModule} from '@angular/material/list';
import { MatIconModule } from '@angular/material/icon';
import { CommonFilterComponent } from './components/common-filter/common-filter.component';
import { FormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import {MatSelectModule} from '@angular/material/select';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {MatRadioModule} from '@angular/material/radio';
import { ConvertMoneyPipe } from './pipes/convert-money.pipe';
import { NgxUiLoaderModule } from "ngx-ui-loader";
import { MatButtonModule } from '@angular/material/button';
import { PaginatorComponent } from './components/paginator/paginator.component';
import { MatPaginatorModule } from '@angular/material/paginator';
import { DateFilterComponent } from './components/date-filter/date-filter.component';
import { MatDialogModule } from '@angular/material/dialog';
import { MatMenuModule } from '@angular/material/menu';
import { MatNativeDateModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { FooterComponent } from './components/footer/footer.component';
import { MatExpansionModule } from '@angular/material/expansion';
import { IntTelPickerComponent } from './components/int-tel-picker/int-tel-picker.component';
import { AnotherIntTelPickerComponent } from './components/another-int-tel-picker/another-int-tel-picker.component';
import { ContactIntTelPickerComponent } from './components/contact-int-tel-picker/contact-int-tel-picker.component';
@NgModule({
  declarations: [
    HeaderComponent,
    SideMenuComponent,
    CommonFilterComponent,
    ConvertMoneyPipe,
    PaginatorComponent,
    DateFilterComponent,
    FooterComponent,
    IntTelPickerComponent,
    AnotherIntTelPickerComponent,
    ContactIntTelPickerComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    NgbModule,
    RouterModule,
    MatSidenavModule,
    MatListModule,
    MatIconModule,
    MatFormFieldModule,
    NgxMatSelectSearchModule,
    MatSelectModule,
    MatSnackBarModule,
    MatCheckboxModule,
    MatRadioModule,
    NgxUiLoaderModule,
    MatButtonModule,
    MatPaginatorModule,
    MatDialogModule,
    MatMenuModule,
    MatNativeDateModule,
    MatDatepickerModule,
    MatExpansionModule
  ],
  exports :[
    HeaderComponent,
    SideMenuComponent,
    CommonFilterComponent,
    ConvertMoneyPipe,
    PaginatorComponent,
    DateFilterComponent,
    FooterComponent,
    IntTelPickerComponent,
    AnotherIntTelPickerComponent,
    ContactIntTelPickerComponent
  ]
})
export class SharedModule { }
