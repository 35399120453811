<div class="login-page signup-page mb-5">
    <div class="container">

        <div class="row responsive-row">
            <div class="col-lg-7 col-md-12">
                <div class="d-flex align-items-center justify-content-center h-100">
                    <div>
                        <img src="../../../../assets/images/signup.png" alt="" class="w-100">
                        <div>
                            <h2>We help you achieve </h2>
                            <h3 class="login-h3">your business goal</h3>
                        </div>
                    </div>
                </div>

            </div>
            <div class="col-lg-5 col-md-12">
                <div class="">
                    <div>
                        <div class="mt-5 logo-margin d-flex align-items-center justify-content-center">
                            <img src="../../../../assets/images/logo-cloud-balance.png" alt="" class="mt-3 cb-logo">
                        </div>
                        <div class="" *ngIf="signUpTab == 1">
                            <form [formGroup]="signUpForm">
                                <mat-stepper labelPosition="bottom" #stepper>
                                    <mat-step>
                                        <ng-template matStepLabel>ACCOUNT</ng-template>
                                        <div>
                                            <mat-form-field class="example-full-width input-style mt-2 w-100"
                                                appearance="outline">
                                                <mat-label>Name*</mat-label>
                                                <input matInput formControlName="user_name">
                                            </mat-form-field>
                                        </div>
                                        <div class=" position-relative">
                                            <mat-form-field class="example-full-width input-style w-100"
                                                appearance="outline">
                                                <mat-label>Email*</mat-label>
                                                <input matInput formControlName="user_email_id">

                                                <mat-error *ngIf="signUpForm.get('user_email_id').invalid">
                                                    <div class="d-flex align-items-center">
                                                        <img src="../../../../assets/images/cautiom.svg" alt=""
                                                            class="me-1">
                                                        <span class="caution">Enter proper email</span>
                                                    </div>
                                                </mat-error>
                                            </mat-form-field>
                                            <!-- <div class="" *ngIf="verifingEmailaddress && !cancelEmailVerification">
                                                <div class="verified-icon">
                                                    <svg version="1.1" xmlns="http://www.w3.org/2000/svg"
                                                        viewBox="0 0 130.2 130.2">
                                                        <circle class="path circle" fill="none" stroke="#73AF55"
                                                            stroke-width="6" stroke-miterlimit="10" cx="65.1" cy="65.1"
                                                            r="62.1" />
                                                        <polyline class="path check" fill="none" stroke="#73AF55"
                                                            stroke-width="6" stroke-linecap="round"
                                                            stroke-miterlimit="10"
                                                            points="100.2,40.2 51.5,88.8 29.8,67.5 " />
                                                    </svg>
                                                </div>
                                            </div>
                                            <div class="cancel-animation"
                                                *ngIf="cancelEmailVerification && !verifingEmailaddress">
                                                <div class="verified-icon">
                                                    <svg version="1.1" xmlns="http://www.w3.org/2000/svg"
                                                        viewBox="0 0 130.2 130.2">
                                                        <circle class="path circle" fill="none" stroke="#D06079"
                                                            stroke-width="6" stroke-miterlimit="10" cx="65.1" cy="65.1"
                                                            r="62.1" />
                                                        <line class="path line" fill="none" stroke="#D06079"
                                                            stroke-width="6" stroke-linecap="round"
                                                            stroke-miterlimit="10" x1="34.4" y1="37.9" x2="95.8"
                                                            y2="92.3" />
                                                        <line class="path line" fill="none" stroke="#D06079"
                                                            stroke-width="6" stroke-linecap="round"
                                                            stroke-miterlimit="10" x1="95.8" y1="38" x2="34.4"
                                                            y2="92.2" />
                                                    </svg>
                                                </div>
                                            </div> -->

                                        </div>

                                        <div>

                                            <div class="verified-number position-relative">
                                                <mat-form-field
                                                    class="example-full-width input-style w-100 mobile-number-field  "
                                                    appearance="outline" floatLabel="always"
                                                    [ngClass]="{'pe-none': verifiedOtp && mobileFieldDisable}">
                                                    <mat-label>Mobile Number*</mat-label>
                                                    <div class="country-code-container">
                                                        <!-- <span class="country-code">+91</span> -->
                                                        <app-int-tel-picker (countryChange)="selectCountry($event)"
                                                            [countryEdit]="countryEdit"
                                                            (click)="stopPropagation($event)"></app-int-tel-picker>
                                                        <input matInput formControlName="user_contact_mobile"
                                                            (keypress)="phoneNumberValidation($event)">
                                                    </div>
                                                    <mat-error *ngIf="signUpForm.get('user_contact_mobile').invalid">
                                                        <div class="d-flex align-items-center">
                                                            <img src="../../../../assets/images/cautiom.svg" alt=""
                                                                class="me-1">
                                                            <span class="caution">Enter 10 digits</span>
                                                        </div>
                                                    </mat-error>
                                                </mat-form-field>
                                                <div class="cancel-animation" *ngIf="cancelMobileVerification">
                                                    <div class="verified-icon">
                                                        <svg version="1.1" xmlns="http://www.w3.org/2000/svg"
                                                            viewBox="0 0 130.2 130.2">
                                                            <circle class="path circle" fill="none" stroke="#D06079"
                                                                stroke-width="6" stroke-miterlimit="10" cx="65.1"
                                                                cy="65.1" r="62.1" />
                                                            <line class="path line" fill="none" stroke="#D06079"
                                                                stroke-width="6" stroke-linecap="round"
                                                                stroke-miterlimit="10" x1="34.4" y1="37.9" x2="95.8"
                                                                y2="92.3" />
                                                            <line class="path line" fill="none" stroke="#D06079"
                                                                stroke-width="6" stroke-linecap="round"
                                                                stroke-miterlimit="10" x1="95.8" y1="38" x2="34.4"
                                                                y2="92.2" />
                                                        </svg>
                                                    </div>
                                                </div>

                                                <!-- <div class="verified-icon" *ngIf="verifiedOtp">

                                                    <svg version="1.1" xmlns="http://www.w3.org/2000/svg"
                                                        viewBox="0 0 130.2 130.2">
                                                        <circle class="path circle" fill="none" stroke="#73AF55"
                                                            stroke-width="6" stroke-miterlimit="10" cx="65.1" cy="65.1"
                                                            r="62.1" />
                                                        <polyline class="path check" fill="none" stroke="#73AF55"
                                                            stroke-width="6" stroke-linecap="round"
                                                            stroke-miterlimit="10"
                                                            points="100.2,40.2 51.5,88.8 29.8,67.5 " />
                                                    </svg>
                                                </div> -->

                                                <!-- <div class="otp-count" *ngIf="otpField && showCountdown">
                                                    <countdown [config]="{leftTime: 180, format: 'mm:ss'}"
                                                        (event)="handleCountdown($event)" #otpCountdown></countdown>
                                                </div>
                                                <div class="otp-count" *ngIf="otpField && !showCountdown">
                                                    <p class="resent-otp-btn cursor-pointer" (click)="verifyNumber()">
                                                        Resend
                                                        OTP</p>
                                                </div> -->
                                            </div>

                                        </div>

                                        <!-- <div class="mb-4 otp-field" *ngIf="otpField">
                                            <p class="text-center">Enter OTP</p>
                                            <div class="text-center">
                                                <ngx-otp-input #otpInput [config]="otpConfiguration"
                                                    (fill)="onOtpInputChange($event)" autofocus="true">
                                                </ngx-otp-input>
                                            </div>
                                            <mat-error *ngIf="otpVerifyError">
                                                <div class="d-flex align-items-center justify-content-center">
                                                    <img src="../../../../assets/images/cautiom.svg" alt=""
                                                        class="me-1">
                                                    <span class="caution">OTP does not match</span>
                                                </div>
                                            </mat-error>
                                        </div> -->

                                        <div>
                                            <mat-form-field class="example-full-width input-style create-password w-100"
                                                appearance="outline">
                                                <mat-label>Create Password*</mat-label>
                                                <input matInput [type]="isDisplayPassword ? 'text' : 'password'"
                                                    formControlName="password">
                                                <mat-icon matSuffix (click)="showPassword()"
                                                    class="cursor-pointer">{{isDisplayPassword ?
                                                    'visibility' :
                                                    'visibility_off' }}</mat-icon>
                                                <!-- <mat-error *ngIf="signUpForm.get('password').invalid">
                                                    <div class="d-flex align-items-center">
                                                        <img src="../../../../assets/images/cautiom.svg" alt=""
                                                            class="me-1">
                                                        <span class="caution"> Password must be at least 8 characters
                                                            long, contain at least 1 special character, 1 uppercase
                                                            letter, 1 lowercase letter and 1 number.
                                                        </span>
                                                    </div>
                                                </mat-error> -->
                                            </mat-form-field>

                                            <div *ngIf="signUpForm.get('password').invalid && 
                                            (signUpForm.get('password').dirty || signUpForm.get('password').touched)">
                                                <div class="d-flex align-items-center">
                                                    <img src="../../../../assets/images/cautiom.svg" alt=""
                                                        class="me-1">
                                                    <span class="caution"> Password must be at least 8 characters
                                                        long, contain at least 1 special character, 1 uppercase
                                                        letter, 1 lowercase letter and 1 number.
                                                    </span>
                                                </div>
                                            </div>



                                            <div class="d-flex align-items-center info mb-3 mt-2">
                                                <mat-icon>info</mat-icon>
                                                <h5 class="mb-0 ms-1 small">
                                                    Password must be atleast 8 characters.
                                                </h5>
                                            </div>
                                        </div>
                                        <div>
                                            <mat-form-field class="example-full-width input-style w-100"
                                                appearance="outline">
                                                <mat-label>Confirm Password*</mat-label>
                                                <input matInput formControlName="confirmPassword"
                                                    [type]="isDisplayConfirmPassword ? 'text' : 'password'">
                                                <mat-icon matSuffix (click)="showConfirmPassword()"
                                                    class="cursor-pointer">{{isDisplayConfirmPassword ?
                                                    'visibility' :
                                                    'visibility_off' }}</mat-icon>
                                                <mat-error
                                                    *ngIf="signUpForm.get('confirmPassword').hasError('passwordNotMatch')">
                                                    <div class="d-flex align-items-center">
                                                        <img src="../../../../assets/images/caution.svg" alt=""
                                                            class="me-1">
                                                        <div class="d-flex align-items-center">
                                                            <img src="../../../../assets/images/cautiom.svg" alt=""
                                                                class="me-1">
                                                            <span class="caution">Password not matched</span>
                                                        </div>
                                                    </div>
                                                </mat-error>

                                            </mat-form-field>
                                        </div>

                                        <button mat-flat-button class="blue-btn btn" matStepperNext
                                            [disabled]="signUpForm.get('user_email_id').invalid || signUpForm.get('user_name').invalid || signUpForm.get('password').invalid || signUpForm.get('confirmPassword').invalid || signUpForm.get('user_contact_mobile').invalid">GET
                                            STARTED</button>


                                    </mat-step>
                                    <mat-step>
                                        <ng-template matStepLabel>ADDRESS</ng-template>
                                        <div>
                                            <mat-form-field class="example-full-width input-style mt-2 w-100"
                                                appearance="outline">
                                                <mat-label>Building number </mat-label>
                                                <input matInput formControlName="user_addr_line1">
                                            </mat-form-field>
                                        </div>
                                        <div>
                                            <mat-form-field class="example-full-width input-style w-100"
                                                appearance="outline">
                                                <mat-label>Society/Flat/Area/Road</mat-label>
                                                <input matInput formControlName="user_addr_line2">
                                            </mat-form-field>
                                        </div>
                                        <div>
                                            <mat-form-field class="example-full-width input-style w-100"
                                                appearance="outline">
                                                <mat-label>Zip code </mat-label>
                                                <input matInput formControlName="user_addr_pin">
                                                <mat-error *ngIf="signUpForm.get('user_addr_pin').hasError('pattern')">
                                                    <div class="d-flex align-items-center">
                                                        <img src="../../../../assets/images/cautiom.svg" alt=""
                                                            class="me-1">
                                                        <span class="caution">Enter 6 digits</span>
                                                    </div>
                                                </mat-error>
                                            </mat-form-field>
                                        </div>
                                        <div class="">
                                            <mat-form-field class="example-full-width input-style w-100"
                                                appearance="outline">
                                                <mat-label>Country</mat-label>
                                                <mat-select formControlName="user_addr_country" (selectionChange)="onCountryChange($event)">

                                                    <div class="menu-search">
                                                        <div class="position-relative  country-list  search-area-width">
                                                            <i
                                                                class="fa fa-search position-absolute search-icon-btn"></i>
                                                            <input matInput class="search-input-from-list"
                                                                placeholder="Search country" [(ngModel)]="countrySearch"
                                                                (click)="stopPropagation($event)"
                                                                [ngModelOptions]="{standalone: true}"
                                                                (input)="filterCountries()" />
                                                        </div>
                                                    </div>

                                                    <ul
                                                        class="m-0 p-0 items-ul country-list items-list-width  position-relative">
                                                        <mat-option *ngFor="let country of filteredCountries"
                                                            [value]="country">{{country?.name}}</mat-option>
                                                    </ul>
                                                </mat-select>
                                            </mat-form-field>
                                        </div>


                                        <div class="">
                                            <mat-form-field class="example-full-width input-style w-100"
                                                appearance="outline">
                                                <mat-label>State</mat-label>
                                                <mat-select formControlName="user_addr_state"
                                                    (selectionChange)="onStateChange($event)">
                                                    <mat-option *ngFor="let state of states"
                                                        [value]="state">{{state?.name}}</mat-option>
                                                </mat-select>
                                            </mat-form-field>
                                        </div>

                                        <div class="" *ngIf="signUpForm.get('user_addr_state').value">
                                            <mat-form-field class="example-full-width input-style w-100"
                                                appearance="outline">
                                                <mat-label>City</mat-label>
                                                <mat-select formControlName="user_addr_city"
                                                    (selectionChange)="onCityChange($event)">
                                                    <mat-option *ngFor="let city of cities"
                                                        [value]="city">{{city?.name}}</mat-option>
                                                </mat-select>
                                            </mat-form-field>
                                        </div>

                                        <div>

                                            <button mat-button class="blue-btn btn mb-4" matStepperNext>Next</button>
                                            <button mat-button class="strok-blue-btn btn"
                                                matStepperPrevious>Back</button>
                                        </div>
                                    </mat-step>
                                    <!-- TAB 3    -->
                                    <mat-step>
                                        <ng-template matStepLabel>COMPANY</ng-template>
                                        <div class="text-center upload-logo-btn" *ngIf="!uploadedLogoSrc">
                                            <label for="thumbnail-upload">
                                                <img src="../../../../assets/images/Upload Photo.png" alt=""
                                                    class="icon-btn cursor-pointer">
                                                <h3 class="upload-text">Upload company logo</h3>
                                            </label>
                                            <input type="file" id="thumbnail-upload" hidden
                                                accept="image/jpeg, image/png, image/jpg" #thumbnailImport
                                                (change)="selectLogo($event)">
                                        </div>
                                        <div class="position-relative image-holder" *ngIf="uploadedLogoSrc">
                                            <div>
                                                <div class="text-center upload-logo-btn">
                                                    <div class="icon-btn text-center">
                                                        <img [src]="uploadedLogoSrc" alt="" class="w-100 h-100">
                                                        <mat-icon class="delete-icon cursor-pointer"
                                                            (click)="uploadedLogoSrc = null">delete_outline</mat-icon>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="" *ngIf="uploadedLogoSrc">
                                                <label for="thumbnail-upload">
                                                    <img src="../../../../assets/images/Upload Photo.png" alt=""
                                                        class="position-absolute small-uploaded-btn-icon cursor-pointer">
                                                </label>
                                                <input type="file" id="thumbnail-upload" hidden
                                                    accept="image/jpeg, image/png, image/jpg" #thumbnailImport
                                                    (change)="selectLogo($event)">
                                            </div>
                                        </div>
                                        <div>
                                            <mat-form-field class="example-full-width input-style w-100"
                                                appearance="outline">
                                                <mat-label>Company Name*</mat-label>
                                                <input matInput formControlName="org_name">
                                                <mat-error *ngIf="signUpForm.get('org_name').invalid">
                                                    <div class="d-flex align-items-center">
                                                        <img src="../../../../assets/images/cautiom.svg" alt=""
                                                            class="me-1">
                                                        <span class="caution">Enter company name</span>

                                                    </div>
                                                </mat-error>
                                            </mat-form-field>
                                        </div>
                                        <div>
                                            <mat-form-field class="example-full-width input-style w-100"
                                                appearance="outline">
                                                <mat-label>GSTN No</mat-label>
                                                <input matInput formControlName="org_gstin">
                                            </mat-form-field>
                                        </div>
                                        <div>
                                            <mat-form-field class="example-full-width input-style w-100"
                                                appearance="outline">
                                                <mat-label>Company Type</mat-label>
                                                <mat-select formControlName="org_type">
                                                    <mat-option value="Private Limited Company">Private Limited
                                                        Company</mat-option>
                                                    <mat-option value="Public Limited Company">Public Limited
                                                        Company</mat-option>
                                                    <mat-option value="One Person Company">One Person
                                                        Company</mat-option>
                                                    <mat-option value="Limited Liability Partnership">Limited Liability
                                                        Partnership</mat-option>
                                                    <mat-option value="Partnership Firm">Partnership Firm</mat-option>
                                                    <mat-option value="Proprietorship Firm">Proprietorship
                                                        Firm</mat-option>
                                                    <mat-option value="Hindu Undivided Family">Hindu Undivided
                                                        Family</mat-option>
                                                </mat-select>
                                            </mat-form-field>
                                        </div>
                                        <div class="d-flex align-items-center want-address">
                                            <div class="radio-btn-design cursor-pointer"
                                                [class.active-redio]="isRadioBtnActive" (click)="toggleRadioBtn()">
                                            </div>
                                            <h4 class="radio-btn-lebel mb-0 ms-2 cursor-pointer"
                                                (click)="toggleRadioBtn()">
                                                Want to enter company
                                                address?</h4>
                                        </div>
                                        <div *ngIf="ischeck" class=" check-margin">
                                            <div>
                                                <mat-form-field class="example-full-width input-style w-100"
                                                    appearance="outline">
                                                    <mat-label>Building number </mat-label>
                                                    <input matInput formControlName="org_addr_line1">
                                                </mat-form-field>
                                            </div>
                                            <div>
                                                <mat-form-field class="example-full-width input-style w-100"
                                                    appearance="outline">
                                                    <mat-label>Society/Flat/Area/Road</mat-label>
                                                    <input matInput formControlName="org_addr_line2">
                                                </mat-form-field>
                                            </div>
                                            <div>
                                                <mat-form-field class="example-full-width input-style w-100"
                                                    appearance="outline">
                                                    <mat-label>Zip code </mat-label>
                                                    <input matInput formControlName="org_addr_post_code">
                                                    <mat-error
                                                        *ngIf="signUpForm.get('org_addr_post_code').hasError('pattern')">
                                                        <div class="d-flex align-items-center">
                                                            <img src="../../../../assets/images/cautiom.svg" alt=""
                                                                class="me-1">
                                                            <span class="caution">Enter 6 digits</span>
                                                        </div>
                                                    </mat-error>
                                                </mat-form-field>
                                            </div>


                                            <div class="">
                                                <mat-form-field class="example-full-width input-style w-100"
                                                    appearance="outline">
                                                    <mat-label>Country</mat-label>

                                                    <mat-select formControlName="org_country" (selectionChange)="onOptionalCountryChange($event)">

                                                        <div class="menu-search">
                                                            <div
                                                                class="position-relative country-list  search-area-width">
                                                                <i
                                                                    class="fa fa-search position-absolute search-icon-btn"></i>
                                                                <input matInput class="search-input-from-list"
                                                                    placeholder="Search country"
                                                                    [(ngModel)]="countryOptionSearch"
                                                                    (click)="stopPropagation($event)"
                                                                    [ngModelOptions]="{standalone: true}"
                                                                    (input)="filterOptionalCountries()" />
                                                            </div>
                                                        </div>

                                                        <ul
                                                            class="m-0 p-0 items-ul country-list items-list-width  position-relative">
                                                            <mat-option *ngFor="let country of filteredOptionCountries"
                                                                [value]="country">{{country?.name}}</mat-option>
                                                        </ul>
                                                    </mat-select>
                                                </mat-form-field>
                                            </div>


                                            <div class="">
                                                <mat-form-field class="example-full-width input-style w-100"
                                                    appearance="outline">
                                                    <mat-label>State</mat-label>
                                                    <mat-select formControlName="org_addr_state"
                                                        (selectionChange)="onOptionalStateChange($event)">
                                                        <mat-option *ngFor="let state of optionStates"
                                                            [value]="state">{{state?.name}}</mat-option>
                                                    </mat-select>
                                                </mat-form-field>
                                            </div>
                                            <div class="" *ngIf="signUpForm.get('org_addr_state').value">
                                                <mat-form-field class="example-full-width input-style w-100"
                                                    appearance="outline">
                                                    <mat-label>City</mat-label>
                                                    <mat-select formControlName="org_addr_city"
                                                        (selectionChange)="onOptionalCityChange($event)">


                                                        <mat-option *ngFor="let city of optionCities"
                                                            [value]="city">{{city?.name}}</mat-option>
                                                    </mat-select>
                                                </mat-form-field>
                                            </div>

                                            <!-- <div>
                                            <mat-form-field class="example-full-width input-style w-100"
                                                appearance="outline">
                                                <mat-label>State</mat-label>
                                                <mat-select formControlName="org_addr_state"
                                                    (selectionChange)="onStateChanges()">
                                                    <mat-option *ngFor="let state of stateCityList"
                                                        [value]="state.key">{{state.key}}</mat-option>
                                                </mat-select>
                                            </mat-form-field>
                                        </div>
                                        <div>
                                            <mat-form-field class="example-full-width input-style w-100"
                                                appearance="outline" *ngIf="signUpForm.get('org_addr_state').value">
                                                <mat-label>City</mat-label>
                                                <mat-select formControlName="org_addr_city">
                                                    <mat-option *ngFor="let city of selectedShippingStateCities"
                                                        [value]="city">{{city}}</mat-option>
                                                </mat-select>
                                            </mat-form-field>
                                        </div> -->
                                        </div>
                                        <div>

                                            <button mat-button class="blue-btn btn mb-4" matStepperNext
                                                [disabled]="signUpForm.get('user_email_id').invalid || signUpForm.get('user_name').invalid || signUpForm.get('password').invalid || signUpForm.get('confirmPassword').invalid || signUpForm.get('user_contact_mobile').invalid ||  signUpForm.get('user_contact_mobile').invalid ||   signUpForm.get('org_name').invalid"
                                                (click)="submitSignUp()"
                                                [ngClass]="{'disabled': btnDisableOtp}">SIGNUP</button>

                                            <button mat-button class="strok-blue-btn btn"
                                                matStepperPrevious>Back</button>
                                        </div>
                                    </mat-step>
                                </mat-stepper>

                            </form>

                        </div>

                        <!-- /// TAB OTP -->
                        <div class="" *ngIf="signUpTab == 2">
                            <div class="text-center">
                                <p class="mt-5 mb-5 otp-instruction">Please check your mail and enter the OTP below</p>

                                <div class="error-toaste mb-4" *ngIf="errorOtpMessage">
                                    <div class="d-flex align-items-center justify-content-between">
                                        <div class="d-flex align-items-center">

                                            <img src="../../../../assets/images/error-toaste.png" alt="">
                                            <h4 class="ms-2 mb-0">Invalid OTP.</h4>
                                        </div>
                                        <mat-icon (click)="errorOtpMessage = false"
                                            class="cursor-pointer">clear</mat-icon>

                                    </div>
                                </div>

                                <div class="login-page verify">

                                    <ng-otp-input #ngOtpInput [config]="config" [formCtrl]="otp"></ng-otp-input>
                                </div>
                                <h6 class="enter-otp mt-2 text-end" *ngIf="countdownDone">
                                    <countdown [config]="countdownConfig" (event)="handleEmailCountdown($event)"
                                        #otpForEmail></countdown>
                                    Sec
                                </h6>
                                <div *ngIf="!countdownDone">
                                    <p class="resent-otp-btn cursor-pointer text-end" (click)="resendEmailOtp()"
                                        [ngClass]="{'disabled': btnDisableOtp}">

                                        Resend
                                        OTP</p>
                                </div>
                            </div>

                            <button mat-flat-button class="blue-btn btn " (click)="submitOTP()"
                                [ngClass]="{'disabled': btnDisableOtp}">VERIFY</button>


                        </div>



                        <div class="row align-items-center dash-margin">
                            <div class="col-5 p-0">
                                <div class="dashed"></div>
                            </div>
                            <div class="col-2">
                                <div class="text-center or-word">Or</div>
                            </div>
                            <div class="col-5 p-0">
                                <div class="dashed"></div>
                            </div>
                        </div>
                        <div>

                            <div class="d-flex align-items-center justify-content-center mt-3">
                                <h4 class="register mb-0">
                                    ALREADY REGISTERED?
                                </h4>
                                <h5 class="signup cursor-pointer ms-2 mb-0" routerLink="/login">
                                    SIGN IN
                                </h5>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>