<div class="filter d-flex align-items-center filter-area">
  <img src="../../../../../../assets/images/filter-results-button.png" alt="">
  <div class="d-flex justify-content-left main-section w-100 flex-wrap">
    <ng-container *ngFor="let filter of filterMenuConfiguration;let i = index">
      <span *ngIf="filter.isImplemented == true" class="chip">
        {{filter.columnName + (filter.value ? ( " : " + filter.value) :"")}}
        <span class="cursor-pointer" (click)="clearImplementedFilter(i)">
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
            stroke="currentColor" stroke-width="2" stroke-linecap="round" outline="white" stroke-linejoin="round"
            class="feather feather-x-circle">
            <circle cx="12" cy="12" r="10"></circle>
            <line x1="15" y1="9" x2="9" y2="15"></line>
            <line x1="9" y1="9" x2="15" y2="15"></line>
          </svg>
        </span>
      </span>
    </ng-container>

    <div class="position-relative">
      <input #filterInput type="text" id="filter" class="autocomplete-input filter-input" placeholder="Filter">
      <div *ngIf="selectedFilter && selectedFilter.type" class="filter-input-section">
        <h4 class="filter-contains-title">{{selectedFilter.columnName}}</h4>

        <div class="filter-content">
        <ng-container *ngIf="selectedFilter.type == 'text'">
          <label for="" class="contains-label">Contains</label>
          <input type="text" class="contains-field" placeholder="Value" autofocus [(ngModel)]="selectedFilter.value">
        </ng-container>

        <ng-container *ngIf="selectedFilter.type == 'radio'">
          <div *ngFor="let option of selectedFilter.options;let i = index"
            class="d-flex justify-content-left radio-input-padding">
            <mat-radio-button  class="contains-radio" id="{{i}}" name="{{selectedFilter.columnName}}"
            value="{{option}}" (change)="selectedFilter.value = option">{{option}}</mat-radio-button>
            <!-- <input type="radio" class="contains-radio" id="{{i}}" name="{{selectedFilter.columnName}}"
              value="{{option}}" [(ngModel)]="selectedFilter.value">
            <label class="ml-1">{{option}}</label> -->
          </div>
        </ng-container>

        <ng-container *ngIf="selectedFilter.type == 'checkbox'">
          <div *ngFor="let option of selectedFilter.options;let i = index"
            class="d-flex justify-content-left radio-input-padding">
            <mat-checkbox class="example-margin" id="{{i}}"
            name="{{selectedFilter.columnName}}" value="{{option.label}}" [(ngModel)]="option.value">{{option.label}}</mat-checkbox>
            <!-- <input type="checkbox" class="contains-checkbox select-check-box" id="{{i}}"
              name="{{selectedFilter.columnName}}" value="{{option.label}}" [(ngModel)]="option.value">
            <label class="ml-1 contains-check-label">{{option.label}}</label> -->
          </div>
        </ng-container>

        <ng-container *ngIf="selectedFilter.type == 'select'">
          <!-- <select name="{{selectedFilter.columnName}}" class="contains-field" [(ngModel)]="selectedFilter.value">
            <option *ngFor="let option of selectedFilter.options" value="{{option}}">{{option}}</option>
          </select> -->
          <mat-form-field class="contains-field">
            <mat-select name="{{selectedFilter.columnName}}" [(ngModel)]="selectedFilter.value">
              <mat-option *ngFor="let option of selectedFilter.options" value="{{option}}">{{option}}</mat-option>
            </mat-select>
          </mat-form-field>
        </ng-container>
        </div>

        <div class="d-flex justify-content-between cursor-pointer filter-controls">
          <span (click)="clearImplementedFilter(null,true)">Cancel</span>
          <span class="apply-label" (click)="implementFilter()"> Apply </span>
        </div>
      </div>
    </div>
  </div>
</div>