<div class="reset-password mb-4">
    <div class="container">
        <div class="width-container mx-auto">
            <div class="d-flex align-items-center justify-content-center pt-2 w-100">
                <img src="../../../../assets/images/logo-cloud-balance.png" alt="" class="mt-3 cb-logo">
            </div>
            <h2 class="text-center reset-heading">Reset Password</h2>

            <div class="" *ngIf="activeTab == 1">
                <div class="d-flex align-items-center justify-content-center">

                    <p class="">Enter the email associated
                        with your account and we’ll send an email with
                        instructions to reset your password.</p>
                </div>

                <div class="error-toaste mt-4" *ngIf="errorOtpMessage">
                    <div class="d-flex align-items-center justify-content-between">
                        <div class="d-flex align-items-center">
                            <img src="../../../../assets/images/error-toaste.png" alt="">
                            <h4 class="ms-2 mb-0">The email doesn't exist in the system.</h4>
                        </div>
                        <mat-icon (click)="errorOtpMessage = false" class="cursor-pointer">clear</mat-icon>
                    </div>
                </div>

                <form [formGroup]="resetPasswordForm">

                    <div class="d-flex align-items-center justify-content-center mt-5">
                        <mat-form-field class="example-full-width input-style create-password-input mb-4 w-100"
                            appearance="outline">
                            <mat-label>Email</mat-label>
                            <input matInput formControlName="email">
                        </mat-form-field>
                    </div>
                </form>
                <div>
                    <button mat-button class="blue-btn btn mt-4 mb-4" (click)="resetForm()"
                        [disabled]="resetPasswordForm.get('email').invalid">SEND</button>
                    <button mat-button class="strok-blue-btn btn" routerLink="/login">Back</button>
                </div>

            </div>



            <div class="" *ngIf="activeTab == 2">
                <div class="d-flex align-items-center justify-content-center">

                    <p class="">Please provide valid password and OTP you received in your mailbox.</p>
                </div>

                <div class="text-center mt-5">

                    <form [formGroup]="createPasswordForm">

                        <div>
                            <mat-form-field class="example-full-width input-style create-password w-100"
                                appearance="outline">
                                <mat-label>Create Password*</mat-label>
                                <input matInput [type]="isDisplayPassword ? 'text' : 'password'"
                                    formControlName="newPassword">
                                <mat-icon matSuffix (click)="showPassword()" class="cursor-pointer">{{isDisplayPassword
                                    ?
                                    'visibility' :
                                    'visibility_off' }}</mat-icon>
                                <!-- <mat-error *ngIf="createPasswordForm.get('newPassword').hasError('minlength')">
                                    <div class="d-flex align-items-center">
                                        <img src="../../../../assets/images/cautiom.svg" alt="" class="me-1">
                                        <span class="caution">Password must be at least 8 characters
                                            long  with special character</span>
                                    </div>
                                </mat-error> -->

                            </mat-form-field>

                            <div
                                *ngIf="createPasswordForm.get('newPassword').invalid && 
                            (createPasswordForm.get('newPassword').dirty || createPasswordForm.get('newPassword').touched)">
                                <div class="d-flex align-items-center">
                                    <img src="../../../../assets/images/cautiom.svg" alt="" class="me-1">
                                    <span class="caution text-start"> Password must be at least 8 characters
                                        long, contain at least 1 special character, 1 uppercase
                                        letter, 1 lowercase letter and 1 number.
                                    </span>
                                </div>
                            </div>

                            <div class="d-flex align-items-center info mb-3 mt-2">
                                <mat-icon>info</mat-icon>
                                <h5 class="mb-0 ms-1 small">
                                    Password must be atleast 8 characters.
                                </h5>
                            </div>
                        </div>

                        <div class="mt-3">
                            <mat-form-field class="example-full-width input-style w-100" appearance="outline">
                                <mat-label>Confirm Password*</mat-label>
                                <input matInput formControlName="confirm_pass"
                                    [type]="isDisplayConfirmPassword ? 'text' : 'password'">
                                <mat-icon matSuffix (click)="showConfirmPassword()"
                                    class="cursor-pointer">{{isDisplayConfirmPassword ?
                                    'visibility' :
                                    'visibility_off' }}</mat-icon>
                                <mat-error *ngIf="createPasswordForm.get('confirm_pass').hasError('passwordNotMatch')">
                                    <div class="d-flex align-items-center">
                                        <img src="../../../../assets/images/caution.svg" alt="" class="me-1">
                                        <div class="d-flex align-items-center">
                                            <img src="../../../../assets/images/cautiom.svg" alt="" class="me-1">
                                            <span class="caution">Password not matched</span>
                                        </div>
                                    </div>
                                </mat-error>

                            </mat-form-field>

                        </div>


                        <div class="mt-3">

                            <ng-otp-input #ngOtpInput [config]="config"
                                [formCtrl]="createPasswordForm.controls['otp']"></ng-otp-input>
                        </div>
                        <h6 class="enter-otp mt-2 mt-40 text-end" *ngIf="countdownDone">
                            <countdown [config]="countdownConfig" (event)="handleCountdown($event)" #otpCountdown>
                            </countdown>
                            Sec
                        </h6>


                        <div *ngIf="!countdownDone">
                            <p class="resent-otp-btn mt-40 cursor-pointer text-end" (click)="resetForm()"
                                [ngClass]="{'disabled': btnDisableOtp}">
                                Resend
                                OTP</p>
                        </div>
                    </form>

                </div>


                <button mat-flat-button class="blue-btn btn " [disabled]="createPasswordForm.invalid"
                    (click)="submitOTP()" [ngClass]="{'disabled': btnDisableOtp}">VERIFY</button>




<div>
                    <!-- <button mat-button class="blue-btn btn mt-4 mb-4" (click)="resetForm()"
                        [disabled]="resetPasswordForm.get('email').value">SEND</button> -->
                    <button mat-button class="strok-blue-btn btn mt-4 mb-4" (click)="emailPage()">Back</button>
                </div>
            </div>

























            <!-- <div class=""
                    *ngIf="!this.resetPasswordForm.get('email').valid && !this.resetPasswordForm.get('phone').valid">

                    <div class="row align-items-center">
                        <div class="col-5">
                            <div class="dashed"></div>
                        </div>
                        <div class="col-2">
                            <div class="text-center or-word">Or</div>
                        </div>
                        <div class="col-5">
                            <div class="dashed"></div>
                        </div>
                    </div>
                </div> -->
            <!-- <div class="d-flex align-items-center justify-content-center mt-3"  *ngIf="!this.resetPasswordForm.get('email').valid">
                    <mat-form-field class="example-full-width input-style  w-100 mobile-number-field"
                        appearance="outline">
                        <mat-label>Mobile Number</mat-label>
                        <div class="country-code-container">
                            <span class="country-code">+91</span>
                            <input matInput formControlName="phone" >
                        </div>
                    </mat-form-field>
                </div> -->



        </div>
    </div>
</div>