import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'divide',
})
export class ConvertMoneyPipe implements PipeTransform {

  


  transform(value: number | string): string {
    if (value === null || value === undefined) return '-';
    
    let num = typeof value === 'string' ? parseFloat(value.replace(/,/g, '')) : value;
    if (isNaN(num)) return '-';
    
    return this.formatINR(num);
  }

  private formatINR(amount: number): string {
    // Ensure the amount has two decimal places
    let amountString = amount.toFixed(2);

    // Split the integer part and the decimal part
    let [integerPart, decimalPart] = amountString.split('.');

    // Format the integer part with commas
    let lastThree = integerPart.substring(integerPart.length - 3);
    let otherNumbers = integerPart.substring(0, integerPart.length - 3);
    if (otherNumbers !== '') lastThree = ',' + lastThree;
    let formattedIntegerPart = otherNumbers.replace(/\B(?=(\d{2})+(?!\d))/g, ",") + lastThree;

    // Combine the formatted integer part with the decimal part
    return '₹ ' + formattedIntegerPart + '.' + decimalPart;
  }
}



