import { Component, EventEmitter, Output } from '@angular/core';
import { SharedService } from '../../services/shared-service/shared.service';

@Component({
  selector: 'app-date-filter',
  templateUrl: './date-filter.component.html',
  styleUrls: ['./date-filter.component.scss']
})
export class DateFilterComponent {
  transactionStartDate: any;
  transactionEndDate: any;

  selectedFilter: any = {
    text: 'All',
    type: null,
    filter: null,
  };
  
  currentYear: number;
  months: any = [];
  abbreviatedMonths: string[] = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

  @Output() dateFilterUpdated: EventEmitter<any> = new EventEmitter();

  constructor(private sharedService: SharedService) {

    this.currentYear = new Date().getFullYear();

    for (let i = 0; i <= 2; i++) {
      const monthNumber = (new Date().getMonth() - i + 12) % 12;
      const monthName = new Date(new Date().getFullYear(), monthNumber).toLocaleString('default', { month: 'long' });
      this.months.push(monthName);
    }

  }

  selectFilter(filter,range = null){

    if(filter == 1){

      let today = new Date();

      this.selectedFilter['text'] = today.toLocaleString('default', {month: 'short'}) + ' ' + today.getDate() + ', ' + this.currentYear;
      this.selectedFilter['type'] = 'First 24 Hours';
      this.selectedFilter['filter'] = 24;

    }
    if(filter == 2){
      this.selectedFilter['text'] = this.generateDateRange(7);
      this.selectedFilter['type'] = 'Last 7 Days';
      this.selectedFilter['filter'] = 7;
    }
    if(filter == 3){
      this.selectedFilter['text'] = this.generateDateRange(28);
      this.selectedFilter['type'] = 'Last 28 Days';
      this.selectedFilter['filter'] = 28;
    }
    if(filter == 4){
      this.selectedFilter['text'] = this.generateDateRange(90);
      this.selectedFilter['type'] = 'Last 90 Days';
      this.selectedFilter['filter'] = 90;
    }
    if(filter == 5){
      this.selectedFilter['text'] = this.generateDateRange(365);
      this.selectedFilter['type'] = 'Last 365 Days';
      this.selectedFilter['filter'] = 365;
    }
    if(filter == 6){
      this.selectedFilter['text'] = 'All';
      this.selectedFilter['type'] = 'Lifetime';
      this.selectedFilter['filter'] = 'All';
    }

    if(filter == 'Year' && range){
      const startDate = new Date(range, 0, 1); 
      const endDate = new Date();

      if (range === this.currentYear) {
        this.selectedFilter['text'] = `${this.getMonthName(0)} 1 - ${this.getMonthName(endDate.getMonth())} ${endDate.getDate()}, ${range}`;
      } else {
        this.selectedFilter['text'] = `${this.getMonthName(0)} 1 - ${this.getMonthName(11)} 31, ${range}`;
      }

      this.selectedFilter['type'] = range;
      this.selectedFilter['filter'] = range;
    }

    if (filter == 'Month' && range) {
      const abbreviatedMonth = range.substring(0, 3);
      let endDate = new Date(this.currentYear, this.abbreviatedMonths.indexOf(abbreviatedMonth) + 1, 0);
      const currentDate = new Date();
    
      if (abbreviatedMonth === this.abbreviatedMonths[currentDate.getMonth()] && currentDate.getDate() < endDate.getDate()) {
        endDate = currentDate;
      }
    
      this.selectedFilter['text'] = `${abbreviatedMonth} 1 - ${endDate.getDate()}, ${this.currentYear}`;
      this.selectedFilter['type'] = range;
      this.selectedFilter['filter'] = range;
    }

    if (filter == 'Custom' && range) {
      const startDate = range.start;
      const endDate = range.end;
      
      const startMonth = this.getMonthName(startDate.getMonth());
      const startDay = startDate.getDate();
      const endMonth = this.getMonthName(endDate.getMonth());
      const endDay = endDate.getDate();
      
      const startYear = startDate.getFullYear();
      const endYear = endDate.getFullYear();
      
      let dateString = `${startMonth} ${startDay} - `;
      
      if (startMonth === endMonth) {
        dateString += `${endDay}, `;
      } else {
        dateString += `${endMonth} ${endDay}, `;
      }
      
      if (startYear === endYear) {
        dateString += startYear;
      } else {
        dateString += `${startYear} - ${endYear}`;
      }
      
      this.selectedFilter['text'] = dateString;
      this.selectedFilter['type'] = 'Custom';
      this.selectedFilter['filter'] = range;
    }

    this.sharedService.setFilter(this.selectedFilter);

  }

  generateDateRange(days: number): string {
    const endDate = new Date();
    const startDate = new Date(endDate);
  
    startDate.setDate(startDate.getDate() - (days));
  
    const startMonth = startDate.toLocaleString('default', { month: 'short' });
    const startDay = startDate.getDate();
    const startYear = startDate.getFullYear();
    
    const endMonth = endDate.toLocaleString('default', { month: 'short' });
    const endDay = endDate.getDate();
    const endYear = endDate.getFullYear();
  
    // Check if the start and end dates have the same year
    if (startYear === endYear) {
      return `${startMonth} ${startDay} - ${endMonth} ${endDay}, ${endYear}`;
    } else {
      return `${startMonth} ${startDay}, ${startYear} - ${endMonth} ${endDay}, ${endYear}`;
    }
  }

  getMonthName(monthNumber) {
    const date = new Date();
    date.setMonth(monthNumber);
    return date.toLocaleString('en-US', { month: 'short' });
  }

  selectDateRange(){
    console.log('Start',this.transactionStartDate);
    console.log('end',this.transactionEndDate);
  }
  
}
