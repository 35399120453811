import { Injectable } from '@angular/core';
import Swal from 'sweetalert2';
import { Observable, Observer } from 'rxjs';

@Injectable({
  providedIn: 'root'
})

export class AlertService {

  constructor() { }

  successAlert(message : any)
  {
    Swal.fire({
      title: 'Success!',
      text: message,
      icon: 'success',
      confirmButtonText: 'Okay',
      showConfirmButton : false,
      timer: 2000,
      showClass: {
        popup: 'animate__animated animate__fadeInDown'
      },
      hideClass: {
        popup: 'animate__animated animate__fadeOutUp'
      }
    })
  }
  successfulAlert(message : any)
  {
    return new Observable<string>((observer: Observer<string>) => {
      Swal.fire({
        title: 'Success!',
        text: message,
        icon: 'success',
        confirmButtonText: 'Okay',
        showConfirmButton : true,
        allowOutsideClick: false,
        // timer: 2000,
        showClass: {
          popup: 'animate__animated animate__fadeInDown'
        },
        hideClass: {
          popup: 'animate__animated animate__fadeOutUp'
        },
        
      }).then((result) => {
        if (result.isConfirmed) {
          observer.next('confirm');
          observer.complete();
        }else {
          observer.next('dismiss');
          observer.complete();
        }
      });
    })
  }
  failureAlert(message : any)
  {
    Swal.fire({
      title: 'Error',
      text: message,
      icon: 'error',
      confirmButtonText: 'Okay',
      showClass: {
        popup: 'animate__animated animate__fadeInDown'
      },
      hideClass: {
        popup: 'animate__animated animate__fadeOutUp'
      }
    })
  }
  confirmAlert(message: any): Observable<string> {
    return new Observable<string>((observer: Observer<string>) => {
      Swal.fire({
        title: 'Are You Sure?',
        text: message,
        icon: 'warning',
        confirmButtonText: 'Confirm',
        denyButtonText: 'Cancel',
        showDenyButton: true,
        confirmButtonColor: '#00b3fe',
        showClass: {
          popup: 'animate__animated animate__fadeInDown'
        },
        hideClass: {
          popup: 'animate__animated animate__fadeOutUp'
        }
      }).then((result) => {
        if (result.isConfirmed) {
          observer.next('confirm');
          observer.complete();
        } else if (result.isDenied) {
          observer.next('cancel');
          observer.complete();
        } else {
          observer.next('dismiss');
          observer.complete();
        }
      });
    });
  }

  warningAlert(message: any){
    Swal.fire({
      title: 'Warning',
      text: message,
      icon: 'warning',
      confirmButtonText: 'Okay',
      showConfirmButton: true,
      allowOutsideClick: false,
      showClass: {
        popup: 'animate__animated animate__fadeInDown'
      },
      hideClass: {
        popup: 'animate__animated animate__fadeOutUp'
      }
    })
}
}
