import { Component, EventEmitter, Input, Output, SimpleChanges } from '@angular/core';
import { countries } from 'src/app/constants/countryCodeData';

@Component({
  selector: 'app-int-tel-picker',
  templateUrl: './int-tel-picker.component.html',
  styleUrls: ['./int-tel-picker.component.scss']
})
export class IntTelPickerComponent {

  countriesList: any = null;
  selectedCountry: any = null;
  isInitialized: boolean = false;
  searchTerm: string = '';
  filteredCountriesList: any[] = [];

  @Output() countryChange: EventEmitter<any> = new EventEmitter<any>();
  @Input() countryEdit: any;

  constructor() {

    this.countriesList = countries;

    this.countriesList.forEach(country => {

      country['flagUrl'] = 'https://flagcdn.com/64x48/' + country.iso.toLowerCase() + '.png';

    });
    this.filteredCountriesList = this.countriesList;
  }

  selectCountry(country) {

    this.selectedCountry = country;
    localStorage.setItem('selectedCountry', JSON.stringify(country));
    console.log(localStorage.getItem('selectedCountry'));

    this.countryChange.emit(this.selectedCountry.code);
  }

  stopPropagation(event: Event): void {
    event.stopPropagation();
  }

  ngOnInit() {

    //     let preSelectedCountry = this.countriesList.find(x=> x.country == 'India');
    // this.selectCountry(preSelectedCountry);

    const savedCountry = localStorage.getItem('selectedCountry');
    console.log(savedCountry);

    if (savedCountry) {
      this.selectedCountry = JSON.parse(savedCountry);
    } else {
      const preSelectedCountry = this.countriesList.find(x => x.country === 'India');
      this.selectCountry(preSelectedCountry);
    }

    this.isInitialized = true;
    this.filteredCountriesList = this.countriesList;


  }

  filterCountries() {
    this.filteredCountriesList = this.countriesList.filter(country =>
      country.country.toLowerCase().includes(this.searchTerm.toLowerCase()) ||
      country.code.includes(this.searchTerm)
    );
  }

  ngOnChanges(changes: SimpleChanges) {
    setTimeout(() => {

      if (changes['countryEdit'] && this.countryEdit && this.isInitialized) {

        const dialCode = this.countryEdit['business_dial_code'];
        const country = this.countriesList.find(c => c.code === dialCode?.substring(1));
        this.selectCountry(country);
      }
    }, 100)
  }

}
