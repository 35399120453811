import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/authentication/services/auth.service';
import { apiRoutes } from 'src/app/constants/apiRoutes';
import { AlertService } from 'src/app/shared/services/alert.service';
import { ApiCallMethodsService } from 'src/app/shared/services/api-call-methods.service';

@Component({
  selector: 'app-select-company',
  templateUrl: './select-company.component.html',
  styleUrls: ['./select-company.component.scss']
})
export class SelectCompanyComponent {
  userDetails: any;
  companyData: any;
  constructor(
    private apiCallMethodsService: ApiCallMethodsService,
    private alertService: AlertService,
    private router: Router,
    private authService: AuthService,) {
    this.userDetails = this.authService.getSessionDetail();
    this.getCompanyName()
  }
  getCompanyName() {
    let orgProfileUrl = apiRoutes.orgProfile + '/' + this.userDetails?.user?.user_id;
    this.apiCallMethodsService.get(orgProfileUrl).subscribe((response: any) => {
      this.companyData = response?.org;

    })
  }
  navigateToDetailsPage(userId: any) {
    this.authService.setId(userId);

    this.router.navigate(['/admin/dashboard'], { queryParams: { id: userId } });
    
  }

}
