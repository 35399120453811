import { Component, EventEmitter, Input, Output } from '@angular/core';
import { PageEvent } from '@angular/material/paginator';

@Component({
  selector: 'app-paginator',
  templateUrl: './paginator.component.html',
  styleUrls: ['./paginator.component.scss']
})
export class PaginatorComponent {
  @Input() totalItems: number;
  @Input() startIndex: number;
  @Input() pageSize: number;
  @Input() pageIndex: number;
  @Input() pageSizeOptions: number[];

  get endIndex(): number {
    const calculatedEndIndex = this.startIndex + this.pageSize;
    return calculatedEndIndex < this.totalItems ? calculatedEndIndex : this.totalItems;
  }

  @Output() pageChanged = new EventEmitter<PageEvent>();
}
