import { Component, OnInit, ViewChild } from '@angular/core';
import { AbstractControl, FormBuilder, FormControl, FormGroup, ValidatorFn, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { CountdownComponent, CountdownConfig, CountdownEvent } from 'ngx-countdown';
import { apiRoutes } from 'src/app/constants/apiRoutes';
import { password } from 'src/app/constants/regex';
import { validationErrorMessages } from 'src/app/constants/validation-error-messages';
import { MustMatch } from 'src/app/shared/helper-function/helper-function';
import { AlertService } from 'src/app/shared/services/alert.service';
import { ApiCallMethodsService } from 'src/app/shared/services/api-call-methods.service';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent {
  otp: any = null;
  @ViewChild('otpInput') otpInput: any;
  @ViewChild('otpCountdown', { static: false }) private countdown: CountdownComponent;
  otpField: boolean = false;
  otpNumber: string;
  verifiedOtp: boolean = false;
  otpVerifyError: boolean = false;
  //
  @ViewChild('ngOtpInput', { static: false }) ngOtpInput: any;
  isDisplayNewPassword: boolean = false;
  isDisplayConfermPassword: boolean = false;
  resetPasswordForm: FormGroup;
  validationErrorMessages: any = validationErrorMessages;
  token: any = null;
  isEmailValid: boolean = false;
  isMobileValid: boolean = false;
  activeTab: any = 1;
  config = {
    allowNumbersOnly: false,
    length: 6,
    isPasswordInput: false,
    disableAutoFocus: false,
    otp: ''
  };
  otpConfiguration: any = {
    otpLength: null,
    otp: null,
    allowNumbersOnly: true,
    disableAutoFocus: true,
  };
  countdownConfig: CountdownConfig = {
    leftTime: 60,
    format: 'ss',
  };
  countdownDone: boolean = true;
  loginMessage: any;
  createPasswordForm: FormGroup;
  isDisplayPassword: boolean = false;
  isDisplayConfirmPassword: boolean = false;
  btnDisableOtp: boolean = false;
  errorOtpMessage: boolean = false;


  constructor(private formBuilder: FormBuilder,
    private apiCallMethodsService: ApiCallMethodsService,
    private alertService: AlertService,
    private router: Router,
    ) {
    this.resetPasswordForm = this.formBuilder.group({
      email: new FormControl('', Validators.compose([Validators.required, Validators.email])),
      //phone: new FormControl('', Validators.compose([Validators.required, Validators.pattern(/^\d{10}$/)])),
    }
    );

    this.createPasswordForm = this.formBuilder.group({
      newPassword: new FormControl('', Validators.compose([Validators.required, Validators.minLength(8), Validators.pattern(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/)])),
      confirm_pass: new FormControl('', Validators.compose([Validators.required])),
      otp: new FormControl('', Validators.compose([Validators.required, this.otpLengthValidator() ])),

    }, {
      validators: this.passwordsMatchValidator
    }
    );
  
    // this.otp = new FormControl('', [Validators.required]);


  }

  passwordsMatchValidator(formGroup: FormGroup) {
    const passwordControl = formGroup.get('newPassword');
    const confirmPasswordControl = formGroup.get('confirm_pass');

    if (confirmPasswordControl.errors && !confirmPasswordControl.errors['passwordNotMatch']) {
      // Don't overwrite an existing error
      return;
    }

    if (passwordControl.value !== confirmPasswordControl.value) {
      confirmPasswordControl.setErrors({ passwordNotMatch: true });
    } else {
      confirmPasswordControl.setErrors(null);
    }
  }
  // passwords(formGroup: FormGroup) {
  //   const passwordControl = formGroup.get('newPassword');
  //   const confirmPasswordControl = formGroup.get('confirm_pass');

  //   if (confirmPasswordControl.errors && !confirmPasswordControl.errors['passwordNotMatch']) {
  //     // Don't overwrite an existing error
  //     return;
  //   }

  //   if (passwordControl.value !== confirmPasswordControl.value) {
  //     confirmPasswordControl.setErrors({ passwordNotMatch: true });
  //   } else {
  //     confirmPasswordControl.setErrors(null);
  //   }
  // }
  // Validator to check if the OTP is 6 digits long
 otpLengthValidator(): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } | null => {
    const otp = control.value;
    return otp && otp.length === 6 ? null : { otpLength: true };
  };
}

  showPassword() {
    this.isDisplayPassword = !this.isDisplayPassword;
  }
  showConfirmPassword() {
    this.isDisplayConfirmPassword = !this.isDisplayConfirmPassword;
  }
  validateEmail() {
    // Assuming your form is named 'resetPasswordForm'
    const emailControl = this.resetPasswordForm.get('email');
    // Update isEmailValid based on email validity
    this.isEmailValid = emailControl.valid;
    return 
  };

  updateMobileValidity() {
    this.isMobileValid = this.resetPasswordForm.get('phone').valid;
     return 
  };
  
  isFormValid(): boolean {
    return this.resetPasswordForm.get('email').valid || this.resetPasswordForm.get('phone').valid;
  }

  resetForm() {
    this.btnDisableOtp = true;

    this.apiCallMethodsService.post(apiRoutes.sendResetLink, this.resetPasswordForm.value).subscribe((response: any) => {
      console.log(response);
      if (response?.success == true) {
        // this.alertService.successAlert(response?.message);
        this.activeTab = 2;
        this.btnDisableOtp = false;
       this.configureOtpAndCountdown();
       this.countdownDone = true;
       this.errorOtpMessage = false;
      } else{
        this.errorOtpMessage = true;

        this.alertService.successAlert(response?.message);
        this.activeTab = 2;

      }

    },(error: any) => {
      this.btnDisableOtp = false;
      this.errorOtpMessage = true;
    //  this.alertService.failureAlert(error.error.message);
    })
  }
  
  configureOtpAndCountdown() {
    this.otpConfiguration.length = 6;
    this.countdownConfig.leftTime = 60;
    
  }



  submitOTP() {
    // this.createPasswordForm.value.otp = this.otp.value;
    if (this.createPasswordForm.invalid) {
      return;
    }
    this.createPasswordForm.value.email = this.resetPasswordForm.value.email;

    delete this.createPasswordForm.value.confirm_pass;

    console.log(this.createPasswordForm.value);
        
    this.apiCallMethodsService.post(apiRoutes.comfirmNewPassword, this.createPasswordForm.value).subscribe((response: any) => {
      this.loginMessage = response;
      if (this.loginMessage?.success == true) {
        // this.alertService.successAlert(response?.message);
        this.alertService.successfulAlert(response?.message).subscribe((userChoice) => {
          if (userChoice == 'confirm') {
            this.router.navigate(['login'])

          }
        });
       
      };
    }, (error: any) => {
         this.alertService.failureAlert(error.error.message);
    });
  }

  emailPage(){
    this.activeTab = 1;
  }

  handleCountdown(e: CountdownEvent) {
    if (e.left == 0) {
      this.countdownDone = false;
      // this._snackBar.dismiss();
    }
  };




    createPassowrdPageDirection(){
      let payload = {
        "token" : this.token
      }
      this.apiCallMethodsService.post(apiRoutes.verifyNewPassword, payload).subscribe((response: any) =>{
        if (response?.success == true) {
          this.alertService.successfulAlert(response?.message).subscribe((userChoice) => {
            if (userChoice == 'confirm') {
              this.router.navigate(['login'])
            }
          });
          this.createPassowrdPageDirection()
        }
        
      } ,(error: any) => {
        this.alertService.failureAlert(error.error.message);
        // this.errorMessage = true;
          })
    }

  ngOnInit(): void {
  }
}
