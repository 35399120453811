import { Component, OnInit } from '@angular/core';
import { Router, Event, NavigationEnd, ActivatedRoute, UrlTree } from '@angular/router';
import { AuthService } from 'src/app/authentication/services/auth.service';
import { apiRoutes } from 'src/app/constants/apiRoutes';
import { ApiCallMethodsService } from 'src/app/shared/services/api-call-methods.service';
import { SidenavThemeServiceService } from '../../services/sidenav-theme-service/sidenav-theme-service.service';
import { ToggleServiceService } from '../../services/toggle-service/toggle-service.service';
import { Observable, map } from 'rxjs';
@Component({
  selector: 'app-side-menu',
  templateUrl: './side-menu.component.html',
  styleUrls: ['./side-menu.component.scss']
})

export class SideMenuComponent implements OnInit {
  isToggled: boolean = false;
  orgId: string;
  userDetails: any;
  companyData: any;
  orgName: any;
  companyProfileDetails: any;

  constructor(private router: Router, private apiCallMethodsService: ApiCallMethodsService,
    private authService: AuthService,
    private activatedRoute: ActivatedRoute,
    private toggleService: ToggleServiceService,
    public themeService: SidenavThemeServiceService,) {
    this.toggleService.isToggled$.subscribe(isToggled => {
      this.isToggled = isToggled;
    });
    this.userDetails = this.authService.getSessionDetail();
    if (this.userDetails) {
      this.orgId = this.authService.getId();
      this.getCompanyDetails()
      this.getCompanyName();
    }

    this.sessionDetails = this.authService.getSessionDetail();
    this.permissions = this.sessionDetails?.rolePermissions?.permissionObjects;
    console.log(this.permissions);

    if (this.sessionDetails?.rolePermissions?.roleId == "67348de5556e35130e62e19f" || this.sessionDetails?.rolePermissions?.roleId == "672c6ed72607887c0cb30c43") {
      this.isEmployee = true;
    }
    else {
      this.isEmployee = false;
    }

  }
  sidebarHovered: boolean = false;
  isActivate: boolean = false;
  navigateToRoute(routeToBeNavigated: any) {
    this.router.navigate([routeToBeNavigated])
  }

  onSidebarHover() {
    this.sidebarHovered = true;
  }

  onSidebarLeave() {
    this.sidebarHovered = false;
  }

  getCompanyName() {
    let orgProfileUrl = apiRoutes.orgProfile + '/' + this.userDetails?.user?.user_id;
    this.apiCallMethodsService.get(orgProfileUrl).subscribe((response: any) => {
      this.companyData = response?.org;

      // Find the company with matching orgId
      const selectedCompany = this.companyData.find(company => company.org_id === this.orgId);
      // Set the default orgName based on orgId
      this.orgName = selectedCompany?.org_name || '';
    });
  }
  setSelectedOrg(orgIds: any, orgName: string) {
    this.orgId = orgIds;
    this.orgName = orgName;
    this.authService.setId(orgIds);
    window.location.reload()
  }
  getCompanyDetails() {
    let url = apiRoutes.selectedOrgProfile + '/' + this.orgId;
    this.apiCallMethodsService.get(url).subscribe((response: any) => {
      this.companyProfileDetails = response?.data[0];
      this.authService.setId(this.orgId);
      this.orgName = this.getOrgName(this.orgId);
    });
  }


  getOrgName(orgId: any): Observable<string> {
    let orgProfileUrl = apiRoutes.orgProfile + '/' + this.userDetails?.user?.user_id;

    // Return an observable instead of using subscribe
    return this.apiCallMethodsService.get(orgProfileUrl).pipe(
      map((response: any) => {
        this.companyData = response?.org;
        const selectedCompany = this.companyData.find(company => company.org_id === orgId);

        return selectedCompany?.org_name || '';
      })
    );
  }
  onOrgChange() {
    this.getOrgName(this.orgId).subscribe(orgName => {
      this.setSelectedOrg(this.orgId, orgName);
    });
  }


  panelOpenState = false;
  logout() {
    this.authService.removeToken();
    setTimeout(() => {
      this.router.navigate(['login']);
    }, 200);
  }

  toggle() {
    this.toggleService.toggle();
  }



  isSidebarClosed: any = false;
  isHovered: boolean = false;
  sessionDetails: any = null;
  permissions: any = null;
  isEmployee: any = false;


  toggleExpandTeam(id) {
    const teamMenu = document.getElementById(id);
    teamMenu?.classList.toggle('expand');
  }

  toggleSidebarCollapse(): void {
    this.isSidebarClosed = !this.isSidebarClosed;
  }

  openSidebarOnHover(): void {
    if (this.isSidebarClosed) {
      this.isHovered = true;
    }
  }

  closeSidebarOnHoverOut(): void {
    if (this.isSidebarClosed) {
      this.isHovered = false;
    }
  }

  closeSidebar(){
    document.querySelector('.sidebar-area').classList.remove('mobile-open');
    document.querySelector('.overlay').classList.remove('mobile-open');
  };

  onNavItemClick() {
    if (window.innerWidth < 992) {
      this.closeSidebar();
    }
  }




  ngOnInit(): void {
  }
}
