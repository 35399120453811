<div class="">
    <div class="container">
        <h1 class="page-heading text-center mt-5 mb-2">Select Company</h1>
        <div class="width-container mx-auto">

            <div class="row">
                <div class="col-md-3 col-6 mt-4" *ngFor="let companyDetails of companyData ">
                    <div class="select-company text-center cursor-pointer" (click)="navigateToDetailsPage(companyDetails?.org_id)">
                        <div class="img">
                            <img src="{{companyDetails?.org_image_link}}" alt="icon3"
                                *ngIf="companyDetails?.org_image_link; else noDpImage">
                        </div>
                        <ng-template #noDpImage>
                            <img src="../../../../../../assets/images/company.png" class="rounded-circle" alt="admin">
                        </ng-template>
                        <h2 class="mb-0 mt-3">{{companyDetails?.org_name}}</h2>
                    </div>
                </div>
                
                
                <div class="mt-4">
                    <div class="row align-items-center">
                        <div class="col-5">
                            <div class="dashed"></div>
                        </div>
                        <div class="col-2">
                            <div class="text-center or-word">Or</div>
                        </div>
                        <div class="col-5">
                            <div class="dashed"></div>
                        </div>
                    </div>
                </div>

                <div class="mx-auto mt-4 w-lg-50 w-md-100">
                    <button mat-flat-button class="strok-blue-btn btn cursor-pointer"
                        routerLink="/company-register">ADD A NEW COMPANY</button>
                </div>
            </div>
        </div>
    </div>
</div>