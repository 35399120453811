import { Injectable } from '@angular/core';
import { CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { AuthService } from 'src/app/authentication/services/auth.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuardService implements CanActivate{

  constructor(public authService:AuthService,
               private router:Router) { }

  canActivate(route:any, state: RouterStateSnapshot) {
    let token = this.authService.getToken();
    
    let orgSelectId = this.authService.getId();

    // if(token)
    // {
    //   console.log('token');
      
    //   if(orgSelectId){
    //     console.log('tokenID');

    //     return true;

    //   }
    //   return true;
    // }
    // else
    // {
    //   console.log('!token');
    //   console.log('!tokenID');

    //      this.router.navigateByUrl('/login',{ replaceUrl: true });
    //      return false;
    // }


    if (token && orgSelectId) {
      // Both token and orgSelectId are present, allow access to the route
      return true;
    }
    else if (token && !orgSelectId) {
      console.log('Organization ID missing, redirecting...');
      
      this.authService.removeToken();
      this.router.navigateByUrl('/login', { replaceUrl: true });

      return false;
    }
    else {
      // Token is missing, redirect to login page
      console.log('No token found, redirecting to login...');
      this.router.navigateByUrl('/login', { replaceUrl: true });
      return false;
    }
  
  }
}
