<div class="login-page">
    <div class="container">

        <div class="row responsive-row">
            <div class="col-lg-7 col-md-12">
                <div class="d-flex align-items-center justify-content-center">
                    <div>
                        <img src="../../../../assets/images/rocket.png" alt="" class="w-100">
                        <div>
                            <h2>Are you ready</h2>
                            <h3 class="login-h3">to take the Leap?</h3>
                        </div>
                    </div>
                </div>

            </div>
            <div class="col-lg-4 col-md-12">
                <div class="">
                    <div>


                        
                        <div class="" *ngIf="loginScreen == 2">
                            <div class="mt-5 mb-5 d-flex align-items-center justify-content-center">
                                <img src="../../../../assets/images/logo-cloud-balance.png" alt="" class="mt-3 cb-logo">
                            </div>

                            
                            <div class="text-center">
                                <p class="mt-5 mb-5 otp-instruction">Please check your mail and enter the OTP below</p>
                              
                              <div class="">
                                  <!-- <ngx-otp-input #otpInput [config]="otpConfiguration"
                                      (fill)="onOtpInputChange($event)" autofocus="true">
                                  </ngx-otp-input> -->
                                  <ng-otp-input #ngOtpInput (onInputChange)="onOtpInputValue($event)"
                                  [config]="config" [formCtrl]="otp"></ng-otp-input>
                              </div>
                              <h6 class="enter-otp mt-2 text-end" >
                                <countdown [config]="countdownConfig" (event)="handleCountdown($event)"
                                    #otpCountdown></countdown>
                                Sec
                            </h6>
                            </div>


                            <button mat-flat-button class="blue-btn btn " (click)="submitOTP()">VERIFY</button>
                        </div>




                    </div>
                </div>
            </div>
        </div>
    </div>
</div>