export const validationErrorMessages = {
  email: [
    { type: 'required', message: 'Email is required',},
    { type: 'email', message: 'Invalid email',},
    { type: 'pattern', message: 'Invalid email',},
  ],
  password:[
    { type: 'required', message: 'Password is required',},
    { type: 'pattern', message: 'Invalid password',},
  ],
  newPassword:[
    { type: 'required', message: 'New password is required',},
    { type: 'pattern', message: 'Invalid password',},
  ],
  confirmPassword:[
    { type: 'required', message: 'Confirm password is required',},
  ],
  name:[
    { type: 'required', message: 'Name is required',},
  ],
  phone_number:[
    { type: 'required', message: 'Phone Number is required',},
  ],
  title:[
    { type: 'required', message: 'Title is required',},
  ],
  first_name:[
    { type: 'required', message: 'First Name is required',},
  ],
  last_name:[
    { type: 'required', message: 'Last Name is required',},
  ],
  dob:[
    { type: 'required', message: 'DOB is required',},
  ],
}
