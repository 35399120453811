import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { apiRoutes } from 'src/app/constants/apiRoutes';
import { password } from 'src/app/constants/regex';
import { validationErrorMessages } from 'src/app/constants/validation-error-messages';
import { MustMatch } from 'src/app/shared/helper-function/helper-function';
import { AlertService } from 'src/app/shared/services/alert.service';
import { ApiCallMethodsService } from 'src/app/shared/services/api-call-methods.service';
import { AuthService } from '../../services/auth.service';

@Component({
  selector: 'app-create-password',
  templateUrl: './create-password.component.html',
  styleUrls: ['./create-password.component.scss']
})
export class CreatePasswordComponent {
  isDisplayPassword: boolean = false;
  isDisplayConfirmPassword: boolean = false;
  createPasswordForm: FormGroup;
  validationErrorMessages: any = validationErrorMessages;
  token: any = null;
  userDetails: any;
  userId: any;
  constructor(private formBuilder: FormBuilder,
    private apiCallMethodsService: ApiCallMethodsService,
    private alertService: AlertService,
    private router: Router,
    private authService: AuthService,
    private activatedRoute: ActivatedRoute,) {
    this.userDetails = this.authService.getSessionDetail();
    console.log(this.userDetails);

    this.createPasswordForm = this.formBuilder.group({
      newPassword: new FormControl('', Validators.compose([Validators.required, Validators.minLength(6)])),
      confirm_pass: new FormControl('', Validators.compose([Validators.required])),
    }, {
      validators: this.passwords.bind(this)
    }
    );
    this.activatedRoute.queryParams.subscribe((params: any) => {
      if (params.id) {
        this.token = params.id;
        console.log(this.token);
        // console.log(this.isEditMode)

      }

    });
    this.postToken()
  }
  log(){
    console.log('this.token');
    this.token = this.activatedRoute.snapshot.queryParams['token'];
  
  }
  postToken(){
    let payload = {
      "token" :  this.activatedRoute.snapshot.queryParams['token'],
    }
  //  this.token = this.activatedRoute.snapshot.queryParams['token'];
    this.apiCallMethodsService.post(apiRoutes.verifyNewPassword, payload).subscribe((response: any) =>{
      this.userId = response;
    },(error: any) => {
      this.alertService.failureAlert(error.error.message);
  })
    
  }
  passwords(formGroup: FormGroup) {
    const passwordControl = formGroup.get('newPassword');
    const confirmPasswordControl = formGroup.get('confirm_pass');

    if (confirmPasswordControl.errors && !confirmPasswordControl.errors['passwordNotMatch']) {
      // Don't overwrite an existing error
      return;
    }

    if (passwordControl.value !== confirmPasswordControl.value) {
      confirmPasswordControl.setErrors({ passwordNotMatch: true });
    } else {
      confirmPasswordControl.setErrors(null);
    }
  }
  // isInvalid(formControlName:any)
  // {
  //   let result:boolean = false;
  //   for(let i = 0; i <  this.validationErrorMessages[formControlName]?.length; i++)
  //   {
  //       if(this.resetPasswordForm?.get(formControlName)?.hasError(this.validationErrorMessages[formControlName][i].type) && (this.resetPasswordForm?.get(formControlName)?.dirty ||  this.resetPasswordForm?.get(formControlName)?.touched))
  //       {
  //           result = true;
  //       }
  //   }
  //   return result;
  // }
  showPassword() {
    this.isDisplayPassword = !this.isDisplayPassword;
  }
  showConfirmPassword() {
    this.isDisplayConfirmPassword = !this.isDisplayConfirmPassword;
  }
  // get f(): any {
  //   return this.resetPasswordForm.controls;
  // }

  resetPassword()
  {
    this.userDetails = this.authService.getSessionDetail();
    console.log(this.userDetails);
    this.token = this.activatedRoute.snapshot.queryParams['token'];
    let resetPasswordForm = {
      "password" : this.createPasswordForm.value.newPassword,
      "token" :  this.token,
      "confirm_pass" : this.createPasswordForm.value.newPassword,
      "user_id": this.userId?.user_id,
    }

   this.apiCallMethodsService.post(apiRoutes.resetPassowrd, resetPasswordForm).subscribe((response: any) =>{
    if(response?.success == true){
      this.alertService.successfulAlert(response?.message).subscribe((userChoice) => {
        if (userChoice == 'confirm') {
          this.router.navigate(['login'])
        }
      });
    }
   },(error: any) => {
      this.alertService.failureAlert(error.error.message);
  })
    
    // let payload = {

    // }
   // this.createPasswordForm.value.token = this.userDetails.
    // if(this.resetPasswordForm.valid){
    //   let resetPasswordPayload = {
    //     password : this.resetPasswordForm.value.newPassword,
    //     token :  this.token
    //   }
    //   // this.apiCallMethodsService.post(apiRoutes.resetPassword,resetPasswordPayload).subscribe((response:any)=>{
    //   //     if(response.success == true)
    //   //     {
    //   //       this.alertService.successAlert(response.message);
    //   //     }
    //   //     setTimeout(()=>{
    //   //       this.router.navigate(['login'])
    //   //     },2000)
    //   // },(error:any)=>{
    //   //   this.alertService.failureAlert(error.error.message);
    //   // })
    // }

  }
  ngOnInit(): void {
  }

}
