import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthenticationModule } from 'src/app/authentication/authentication.module';
import { AuthGuardService } from './guards/auth-guard/auth-guard.service';
import { LogoutGuardService } from './guards/logout-guard/logout-guard.service';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'admin',
    pathMatch: 'full',
  },
  {
    path: 'auth',
    loadChildren: () =>
      import('src/app/authentication/authentication.module').then(
        (m) => m.AuthenticationModule
      ),
    canActivate: [LogoutGuardService]
  },
  {
    path: 'admin',
    loadChildren: () =>
      import('src/app/main/main.module').then(
        (m) => m.MainModule
      ),
    canActivate: [AuthGuardService]
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    scrollPositionRestoration: 'enabled',
    paramsInheritanceStrategy: 'always',
    initialNavigation: 'enabledBlocking' 
  })],
  exports: [RouterModule,
    AuthenticationModule
  ],
})
export class AppRoutingModule {

}
