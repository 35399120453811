import { Component, ElementRef, Input, ViewChild, Output, EventEmitter } from '@angular/core';
import autocomplete from 'autocompleter';
@Component({
  selector: 'app-common-filter',
  templateUrl: './common-filter.component.html',
  styleUrls: ['./common-filter.component.scss']
})
export class CommonFilterComponent {
  selectedFilter:any = null;
  test: Boolean = true;
  @ViewChild('filterInput') filterInput: ElementRef<HTMLInputElement>;
  @Input() filterMenuConfiguration;
  @Output() filterApplied = new EventEmitter();
  implementFilter()
  {
    let index = this.filterMenuConfiguration.findIndex(x => x.columnName == this.selectedFilter.columnName);
    
    if(this.selectedFilter.type == "checkbox")
    {
      let checkedOptions = this.selectedFilter.options.filter(x => x.value == true );
      this.selectedFilter.value = "";
      this.selectedFilter.id = '';
      for(let i = 0 ; i < checkedOptions.length ; i++)
      {
        this.selectedFilter.value = this.selectedFilter.value + checkedOptions[i].label + ((i != checkedOptions.length-1)  ? ',' : "");
        if(checkedOptions[i].id){
          this.selectedFilter['id'] = this.selectedFilter.id + checkedOptions[i].id + ((i != checkedOptions.length-1)  ? ',' : "");
        }
      }
    }
    this.filterMenuConfiguration[index].value = this.selectedFilter.value;
    this.filterMenuConfiguration[index].isImplemented = true;
    
    console.log(this.selectedFilter);
    this.selectedFilter = null;
    const filterRef = this.filterInput.nativeElement;
    filterRef.value = null;

    this.filterApplied.emit(this.filterMenuConfiguration);
    console.log(this.filterMenuConfiguration);
    
  }

  clearImplementedFilter(filterIndex = null,isCanceled)
  {
    
    this.filterMenuConfiguration.forEach(element => {
      if(element.columnName == 'Categories'){

        if(element.options && element.options.length > 0){
          element.options.forEach(option =>{
            option.value = null;
          })
        }

      }
    });
    
    let index = (filterIndex != null) ? filterIndex : this.filterMenuConfiguration.findIndex(x => x.columnName == this.selectedFilter.columnName);
    this.filterMenuConfiguration[index].isImplemented = false;
    this.filterMenuConfiguration[index].value = null;
    this.selectedFilter = null;
    const filterRef = this.filterInput.nativeElement;
    filterRef.value = null;
    if(!isCanceled){
      this.filterApplied.emit(this.filterMenuConfiguration);
    }
  }

  ngOnInit()
  {
    setTimeout(()=>{
      // const filterRef = <HTMLInputElement>document.getElementById('filter');
      const filterRef = this.filterInput.nativeElement;
      const uniqueId = 'filter_' + Math.random().toString(36).substr(2, 9); // Generate a unique ID
      filterRef.id = uniqueId;
      
      autocomplete({
        input: filterRef,
        className: 'autocomplete-customizations',
        fetch: (text, update) => {
            text = text.toLowerCase();
            var suggestions = this.filterMenuConfiguration.filter(n => n.columnName.toLowerCase().startsWith(text.toLowerCase()) && n.isImplemented == false);
            update(suggestions);
        },
        onSelect: (item:any) => {
          filterRef.value = null;
          filterRef.blur();
          this.selectedFilter = item;
          if(item.type == null)
          {
            this.implementFilter();
          }
        },
        render:(item, currentValue: string): HTMLDivElement | undefined => {
          const itemElement = document.createElement('div');
          itemElement.textContent = item['columnName'];
          return itemElement;
        },
        showOnFocus: true
      });
    },200)

  }
}
