import { Injectable, EventEmitter } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class SharedService {
  
  filterUpdated: EventEmitter<any> = new EventEmitter<any>();

  constructor() { }

  setFilter(filter: any) {
    this.filterUpdated.emit(filter);
  }
  
}
