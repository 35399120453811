import { Injectable } from '@angular/core';
@Injectable({
  providedIn: 'root'
})
export class AuthService {
  constructor() { }
  private companyIdKey = 'company_id';

  setToken(tokenDetail) {
    // localStorage.setItem('token', tokenDetail);
    localStorage.setItem('token', JSON.stringify(tokenDetail));

  }

  setId(companyId: string) {
    localStorage.setItem('id', companyId);
    console.log(companyId);

  };

  setRememberDetails(details: any): void {
    localStorage.setItem('login', JSON.stringify(details));
    console.log(details);
    
  }
  
  // getId(): string {
  //   const id = localStorage.getItem('id');
  //   return id ? JSON.parse(id) : ``;

  // }
  getId(): string {
    const id = localStorage.getItem('id');
    return id;
  };

  getLoginDetail(): any {
  const loginDetail = localStorage.getItem('login');
  return loginDetail ? JSON.parse(loginDetail) : ``;
}

  getToken(): string {
    const token = localStorage.getItem('token');
    return token ? JSON.parse(token)?.token?.token : ``;

    // return token ? token?.token.token : ``;
  }
 
  // getSessionDetail() {
  //   return localStorage.getItem('token') ? JSON.parse(localStorage.getItem('token')) : null;
  // }

  getSessionDetail() {
    return localStorage.getItem('token') ? JSON.parse(localStorage.getItem('token')) : ``;
  }
  removeToken() {
    localStorage.removeItem("token");
  };
  removeId() {
    localStorage.removeItem("id");
  };
  removeLoginDetails() {
    localStorage.removeItem("login");    
  };
  isAuthenticatedId(): boolean {
    const id = this.getId();
    return id?.length > 0 ? true : false;
  }
  isAuthenticated(): boolean {
    const token = this.getToken();
    return token?.length > 0 ? true : false;
  }
}
