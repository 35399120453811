<div class="login auth-inner row m-0">
    <div class="d-none d-lg-flex col-lg-8 align-items-center p-5 img-section">
        <div class="w-100 d-lg-flex align-items-center justify-content-center px-5">
            <img class="img-fluid" src="./../../../../assets/images/forgot-password-v2.svg" alt="image">
        </div>
    </div>
    <div class="d-flex col-lg-4 align-items-center auth-bg px-2 p-lg-5 login-form">
        <div class="col-12 col-sm-8 col-md-6 col-lg-12 px-xl-2 mx-auto p-5">
            <h2 class="card-title fw-bold mb-1">
                Forgot Password? 🔒
            </h2>
            <p class="card-text mb-2">Enter your phone number and we'll send you OTP to reset your password</p>
            <form class="auth-login-form mt-2" [formGroup]="forgotPasswordForm" (ngSubmit)="submitForgotPassword()">

                <div class="mb-1">

                    <label class="form-label fw-bold" for="">Phone Number</label>

                    <div class="d-flex align-items-center">

                        <div class="country-picker">
                            <button class="country-code-picker cursor-pointer" mat-button [matMenuTriggerFor]="menu">
                                <span class="d-inline-block country-flag">
                                    <img src="../../../../assets/images/country-india.png" alt="">
                                </span>
                                <span class="d-inline-block">
                                    <p class="mb-0">{{selectedCountryCode.replace('+','')}}</p>
                                </span>
                                <span class="d-inline-block">
                                    <mat-icon>keyboard_arrow_down</mat-icon>
                                </span>
                            </button>
                            
                            <mat-menu #menu="matMenu">
                              <div class="country-flag mx-0" mat-menu-item (click)=" selectedCountryCode = '+91' ">
                                  <img src="../../../../assets/images/country-india.png" class="country-flag" alt="">
                                  <p class="mb-0 d-inline-block">India +91</p>
                              </div>
                            </mat-menu>
                        </div>

                        <div class="w-100">
                            <input class="form-control username-input" (keypress)="validatePhoneNumber($event)" type="number" placeholder="Enter Your Phone Number" formControlName="phoneNumber">
                        </div>

                    </div>
                    <!-- <div class="error" *ngIf="forgotPasswordForm.get('phoneNumber')?.hasError('required') &&
                                (forgotPasswordForm.get('phoneNumber')?.dirty ||  forgotPasswordForm.get('phoneNumber')?.touched)">
                                <small>Phone number is required</small>
                    </div> -->
                    <div class="error" *ngIf="forgotPasswordForm.get('phoneNumber')?.hasError('required') &&
                                (forgotPasswordForm.get('phoneNumber')?.dirty)">
                                <small>Phone number is required</small>
                    </div>
                </div>

                <button type="submit" [disabled] = "forgotPasswordForm.invalid"  class="btn btn-primary w-100 waves-effect waves-float waves-light">
                    Send OTP
                </button>
                <p class="text-center mt-2">
                    <a [routerLink]="'/login'" class="theme">
                        <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-chevron-left"><polyline points="15 18 9 12 15 6"></polyline></svg>
                     Back to login
                    </a>
                </p>
            </form>
        </div>
    </div>
</div>

