<div class="d-flex">

    <div class="country-picker">
        <button class="country-code-picker cursor-pointer" mat-button [matMenuTriggerFor]="menu">

            <div class="d-flex align-items-center">

                <span class="country-flag">
                    <img [src]="selectedCountry?.flagUrl" id="shown-flag" alt="">
                </span>
                <span class="d-inline-block">
                    <p class="mb-0">+{{selectedCountry?.code}}</p>
                </span>
                <span class="d-inline-block">
                    <mat-icon>keyboard_arrow_down</mat-icon>
                </span>

            </div>

        </button>
        <mat-menu  class="num-picker" #menu="matMenu">

            <div class="menu-search">
                <div class="position-relative search-area-width">
                    <i class="fa fa-search position-absolute search-icon-btn"></i>
                    <input type="text" class="search-input-from-list" [(ngModel)]="searchTerm"
                        (ngModelChange)="filterCountries()" (click)="stopPropagation($event)"
                        placeholder="Search country">
                </div>
            </div>

            <ul class="m-0 p-0 items-ul items-list-width  position-relative">

                <li class="list-down sku-list">
                    <div class="country-flag mx-0" mat-menu-item *ngFor="let country of filteredCountriesList"
                        (click)="selectConCountry(country)">
                        <img [src]="country?.flagUrl" class="country-flag menu-flag" alt="">
                        <p class="mb-0 d-inline-block menu-name">{{country?.country + ' +' + country?.code}}</p>
                    </div>

                </li>
            </ul>

            <!-- <input type="text" [(ngModel)]="searchTerm" (ngModelChange)="filterCountries()" (click)="stopPropagation($event)" placeholder="Search country"> -->



        </mat-menu>
    </div>

</div>