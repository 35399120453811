import { Component, OnInit, HostListener } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { AuthService } from 'src/app/authentication/services/auth.service';
import { apiRoutes } from 'src/app/constants/apiRoutes';
import { ApiCallMethodsService } from '../../services/api-call-methods.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { SidenavThemeServiceService } from '../../services/sidenav-theme-service/sidenav-theme-service.service';
import { ToggleServiceService } from '../../services/toggle-service/toggle-service.service';
@Component({
    selector: 'app-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
    hideHeaderRoutes = ['test-builder'];
    isDetailsPage: any = null;
    showHeader: any = true;
    isSticky: boolean = false;
    headerName: string = '';
    orgCategory: string = '';
    userDetails: any;
    sellerId: any;
    getSellerDetails: any;
    // @HostListener('window:scroll', ['$event'])
    // checkScroll() {
    //     const scrollPosition = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0;
    //     if (scrollPosition >= 50) {
    //         this.isSticky = true;
    //     } else {
    //         this.isSticky = false;
    //     }
    // }

    isToggled = false;
    companyData: any;
    orgId: string;
    companyProfileDetails: any;
    orgName: any;

    constructor(private authService: AuthService, private router: Router,
        private apiCallMethodsService: ApiCallMethodsService,
        private _snackBar: MatSnackBar,
        private toggleService: ToggleServiceService,
        public themeService: SidenavThemeServiceService,) {
        this.toggleService.isToggled$.subscribe(isToggled => {
            this.isToggled = isToggled;
        });
        this.userDetails = this.authService.getSessionDetail();
        console.log(this.userDetails);

        // this.router.events.subscribe(event => {
        //   if (event instanceof NavigationEnd) {
        //     let result = this.sidemenuChangeRoutes.find(x=> event.url.toLowerCase().includes(x.toLowerCase()));
        //     this.isDetailsPage = result ? true : false;  

        //     let showHeaderResult = this.hideHeaderRoutes.find(x=> event.url.toLowerCase().includes(x.toLowerCase()));
        //     this.showHeader = showHeaderResult ? false : true;

        //   }
        // });

        if (this.userDetails) {
            this.orgId = this.authService.getId();
            console.log(this.orgId);
            this.getCompanyDetails()
            this.getCompanyName();
        }

    }
    getCompanyName() {
        let orgProfileUrl = apiRoutes.orgProfile + '/' + this.userDetails?.user?.user_id;
        this.apiCallMethodsService.get(orgProfileUrl).subscribe((response: any) => {
            this.companyData = response?.org;
            const selectedCompany = this.companyData.find(company => company.org_id === this.orgId);
            this.orgName = selectedCompany?.org_name || '';
        });
    }

    setSelectedOrg(orgIds: any, orgName: string) {
        this.orgId = orgIds;
        this.authService.setId(orgIds);
        this.orgName = orgName;
        setTimeout(() => {
            this.router.navigate(['admin/dashboard']).then(() => {
                window.location.reload();
            });

        }, 200)
    }

    getCompanyDetails() {
        let url = apiRoutes.selectedOrgProfile + '/' + this.orgId;
        this.apiCallMethodsService.get(url).subscribe((response: any) => {
            this.companyProfileDetails = response?.data[0];
            console.log(this.companyProfileDetails);

            this.authService.setId(this.orgId);
            this.orgName = this.getOrgName(this.orgId);
        });
    }

    getOrgName(orgId: any) {
        let orgProfileUrl = apiRoutes.orgProfile + '/' + this.userDetails?.user?.user_id;
        this.apiCallMethodsService.get(orgProfileUrl).subscribe((response: any) => {
            this.companyData = response?.org;

            const selectedCompany = this.companyData.find(company => company.org_id === orgId);

            return selectedCompany?.org_name || '';
        });

    }
    navigateToDetailsPage(supplierId: any) {
        this.router.navigate(['/admin/business-management'], { queryParams: { id: supplierId } });
    }
    redirectToBusinessManagement() {
        this.router.navigate(['/company-register']);
    }

    logOut() {
        this.authService.removeId();
        this.authService.removeToken();
        this.router.navigateByUrl('login', { replaceUrl: true });
    }

    collapseNav() {
        document.querySelector('.mat-drawer-container').classList.toggle('collapsed');
    }


    ngOnInit(): void {
    }



    toggleTheme() {
        this.themeService.toggleTheme();
    }

    toggle() {
        this.toggleService.toggle();
    }

    logout() {
        this.authService.removeToken();
        setTimeout(() => {
            this.router.navigate(['login']);
        }, 200);
    }

    openSidebar() {
        document.querySelector('.sidebar-area').classList.add('mobile-open');
        document.querySelector('.overlay').classList.add('mobile-open');
      }
    
      closeSidebar() {
        document.querySelector('.sidebar-area').classList.remove('mobile-open');
        document.querySelector('.overlay').classList.remove('mobile-open');
      }
    
}
