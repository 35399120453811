import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ToggleServiceService {

  constructor() { }
  private isToggled = new BehaviorSubject<boolean>(false);

  get isToggled$() {
      return this.isToggled.asObservable();
  }

  toggle() {
      this.isToggled.next(!this.isToggled.value);
  }
}
