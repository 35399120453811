<div class="login-page">
    <div class="container">

        <div class="row responsive-row">
            <div class="col-lg-7 col-md-12">
                <div class="d-flex align-items-center justify-content-center">
                    <div>
                        <img src="../../../../assets/images/rocket.png" alt="" class="w-100">
                        <div>
                            <h2>Are you ready</h2>
                            <h3 class="login-h3">to take the Leap?</h3>
                        </div>
                    </div>
                </div>

            </div>
            <div class="col-lg-5 col-md-12">
                <div class="">
                    <div>
                        <div class="" *ngIf="loginScreen == 1">

                            <div class="mt-5 mb-5 d-flex align-items-center justify-content-center">
                                <img src="../../../../assets/images/logo-cloud-balance.png" alt="" class="mt-3 cb-logo">
                            </div>
                            <div class="error-toaste mb-4" *ngIf="errorMessage">
                                <div class="d-flex align-items-center justify-content-between">
                                    <div class="d-flex align-items-center">

                                        <img src="../../../../assets/images/error-toaste.png" alt="">
                                        <h4 class="ms-2 mb-0">Incorrect email or password.</h4>
                                    </div>
                                    <mat-icon (click)="errorMessage = false" class="cursor-pointer">clear</mat-icon>

                                </div>
                            </div>
                            <form [formGroup]="loginForm">
                                <div class="">
                                    <mat-form-field class="example-full-width input-style w-100" appearance="outline">
                                        <mat-label>Email</mat-label>
                                        <input matInput formControlName="email">
                                        <!-- <mat-error *ngIf="loginForm.get('email').invalid && loginForm.get('email').touched"> -->
                                        <mat-error *ngIf="loginForm.get('email').invalid">

                                            <div class="d-flex align-items-center">
                                                <img src="../../../../assets/images/cautiom.svg" alt="" class="me-1">
                                                <span class="caution">Enter valid email</span>
                                            </div>
                                        </mat-error>
                                    </mat-form-field>
                                </div>
                                <div>
                                    <mat-form-field class="example-full-width input-style w-100 mt-2"
                                        appearance="outline">
                                        <mat-label>Password</mat-label>
                                        <input matInput [type]="isDisplayPassword ? 'text' : 'password'"
                                            formControlName="password">
                                        <mat-icon matSuffix (click)="showPassword()"
                                            class="cursor-pointer">{{isDisplayPassword
                                            ? 'visibility' :
                                            'visibility_off' }}</mat-icon>
                                        <!-- <mat-error *ngIf="loginForm.get('password').hasError('required')">
                                                Password is required
                                            </mat-error> -->
                                        <mat-error *ngIf="loginForm.get('password').hasError('minlength')">
                                            <div class="d-flex align-items-center">
                                                <img src="../../../../assets/images/cautiom.svg" alt="" class="me-1">
                                                <span class="caution">Password must be at least 8 characters long with
                                                    special character</span>
                                            </div>
                                        </mat-error>
                                    </mat-form-field>
                                </div>
                                <div class="d-flex align-items-center justify-content-between">
                                    <span class="forget-password cursor-pointer d-block mt-2"
                                        routerLink="/auth/reset-password">Forgot
                                        password?</span>
                                    <div class=" mt-2">
                                        <div class="form-check d-flex align-items-center cursor-pointer">
                                            <input class="form-check-input cursor-pointer" type="checkbox"
                                                name="remember" id="rememberMe" (click)="rememberMeBox()"
                                                formControlName="remember_me">
                                            <label for="rememberMe" class="form-check-label cursor-pointer">Remember
                                                me</label>
                                        </div>
                                    </div>

                                </div>

                                <button mat-flat-button class="blue-btn btn " (click)="submitLogin()"
                                    [disabled]="loginForm.invalid"
                                    [ngClass]="{'disabled': btnDisableOtp}">LOGIN</button>


                            </form>


                            <div>
                                <div class="d-flex align-items-center justify-content-center register-btn">
                                    <h4 class="register mb-0">
                                        NOT YET REGISTERED?
                                    </h4>
                                    <h5 class="signup cursor-pointer ms-2 mb-0" routerLink="/auth/signup">
                                        SIGN UP
                                    </h5>
                                </div>
                            </div>

                        </div>




                        <div class="" *ngIf="loginScreen == 2">
                            <div class="mt-5 mb-5 d-flex align-items-center justify-content-center">
                                <img src="../../../../assets/images/logo-cloud-balance.png" alt="" class="mt-3 cb-logo">
                            </div>

                            <div class="error-toaste mb-4" *ngIf="errorOtpMessage">
                                <div class="d-flex align-items-center justify-content-between">
                                    <div class="d-flex align-items-center">

                                        <img src="../../../../assets/images/error-toaste.png" alt="">
                                        <h4 class="ms-2 mb-0">Invalid OTP.</h4>
                                    </div>
                                    <mat-icon (click)="errorOtpMessage = false" class="cursor-pointer">clear</mat-icon>

                                </div>
                            </div>
                            <div class="text-center">
                                <p class="mt-5 mb-5 otp-instruction">We sent a temporary sign up code to <span class="email-value">{{
                                    loginForm.get('email').value }}</span></p>

                                <div class="">

                                    <ng-otp-input #ngOtpInput [config]="config" [formCtrl]="otp"></ng-otp-input>
                                </div>
                                <h6 class="enter-otp mt-2 text-end" *ngIf="countdownDone">
                                    <countdown [config]="countdownConfig" (event)="handleCountdown($event)"
                                        #otpCountdown></countdown>
                                    Sec
                                </h6>


                                <div *ngIf="!countdownDone">
                                    <p class="resent-otp-btn cursor-pointer text-end" (click)="resendEmailOtp()"
                                        [ngClass]="{'disabled': btnDisableOtp}">

                                        Resend
                                        OTP</p>
                                </div>
                            </div>

                            <div class="">
                                <button mat-flat-button class="blue-btn btn " (click)="submitOTP()"
                                    [ngClass]="{'disabled': btnDisableOtp}">VERIFY</button>

                                <button mat-button class="strok-blue-btn btn mt-4 mb-4"
                                    (click)="firstPage()">Back</button>
                            </div>

                        </div>




                    </div>
                </div>
            </div>
        </div>
    </div>
</div>