import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { apiRoutes } from 'src/app/constants/apiRoutes';
import { validationErrorMessages } from 'src/app/constants/validation-error-messages';
import { AlertService } from 'src/app/shared/services/alert.service';
import { ApiCallMethodsService } from 'src/app/shared/services/api-call-methods.service';
import { AuthService } from '../../services/auth.service';
import { MatSnackBar, MatSnackBarModule } from '@angular/material/snack-bar';
import { CountdownConfig, CountdownEvent, CountdownComponent } from 'ngx-countdown';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  otp: any = null;
  @ViewChild('otpInput') otpInput: any;
  @ViewChild('otpCountdown', { static: false }) private countdown: CountdownComponent;
  otpField: boolean = false;
  otpNumber: string;
  // otpConfiguration: any = {
  //   otpLength: 6,
  //   allowNumbersOnly: true,
  //   disableAutoFocus: true
  // };
  verifiedOtp: boolean = false;
  otpVerifyError: boolean = false;
  //
  @ViewChild('ngOtpInput', { static: false }) ngOtpInput: any;
  config = {
    allowNumbersOnly: false,
    length: 6,
    isPasswordInput: false,
    disableAutoFocus: false,
    otp: ''
  };
  otpConfiguration: any = {
    otpLength: null,
    otp: null,
    allowNumbersOnly: true,
    disableAutoFocus: true,
  };
  countdownConfig: CountdownConfig = {
    leftTime: 60,
    format: 'ss',
  };
  countdownDone: boolean = true;


  loginForm: FormGroup;
  generateOtpForm: FormGroup;
  validationErrorMessages: any = validationErrorMessages;
  isDisplayPassword: boolean = false;
  isRememberMe: boolean = false;
  loginScreen: any = 1;
  loginMessage: any;
  errorMessage: boolean = false;
  loginDetails: any;
  jsonLoginDetails: any;
  errorOtpMessage: boolean = false;
  btnDisableOtp: boolean = false;

 
  showPassword() {
    this.isDisplayPassword = !this.isDisplayPassword;
  }
  constructor(private formBuilder: FormBuilder,
    private apiCallMethodsService: ApiCallMethodsService,
    private alertService: AlertService,
    private router: Router,
    private authService: AuthService,
    private _snackBar: MatSnackBar,) {
    this.loginForm = this.formBuilder.group({
      email: new FormControl('', Validators.compose([Validators.required, Validators.email])),
      password: new FormControl('', Validators.compose([Validators.required, Validators.minLength(6)])),
      remember_me: false,
    });
    this.loginDetails = this.authService.getLoginDetail();
    console.log(this.loginDetails);
    this.otp = new FormControl('', [Validators.required]);

    this.patchLoginForm()
  }
  configureOtpAndCountdown() {
    this.otpConfiguration.length = 6;
    this.countdownConfig.leftTime = 60;
  }
  patchLoginForm() {
    if (this.loginDetails) {

      this.loginForm.setValue({
        email: this.loginDetails.email || '',
        password: this.loginDetails.password || '',
        remember_me: this.loginDetails.remember_me || false,
      });
    }
  }
  rememberMeBox() {
    if (this.loginForm.value.remember_me === false) {
      this.isRememberMe = true;
      this.loginForm.value.remember_me = this.isRememberMe;
      this.authService.setRememberDetails(this.loginForm.value);
    }

    else if (this.loginForm.value.remember_me === true) {
      console.log(this.loginForm.value.remember_me);
      this.isRememberMe = false;
      this.loginForm.value.remember_me = this.isRememberMe;
      this.authService.removeLoginDetails();
    }
  }


  isInvalid(formControlName: any) {
    let result: boolean = false;
    for (let i = 0; i < this.validationErrorMessages[formControlName]?.length; i++) {
      if (this.loginForm?.get(formControlName)?.hasError(this.validationErrorMessages[formControlName][i].type) && (this.loginForm?.get(formControlName)?.dirty || this.loginForm?.get(formControlName)?.touched)) {
        result = true;
      }
    }
    return result;
  }


  toggleLoginOptions() {
    this.loginScreen = this.loginScreen == 1 ? 2 : 1;
  }

  submitLogin() {
    this.btnDisableOtp = true;

    this.apiCallMethodsService.post(apiRoutes.login, this.loginForm.value).subscribe((response: any) => {
      this.loginMessage = response;
      if (this.loginMessage?.success == true) {
        // this.alertService.successAlert(response?.message);
        this.btnDisableOtp = false;

        this.loginScreen = 2;
        setTimeout(() => {
          // this.authService.removeId();
          // this.authService.setToken(response);
          // this.router.navigate(['admin/business-management/select-company']);
        }, 2000)
      };
    }, (error: any) => {
      //    this.alertService.failureAlert(error.error.message);
      this.errorMessage = true;
      this.btnDisableOtp = false;
    });
  }

  onOtpInputChange(value: string): void {

    if (value) {
      console.log(value);


      // let payload = {
      //   "phone_number": this.signUpForm.value.user_contact_mobile,
      //   "otp": value,
      // };

      // this.apiCallMethodsService.post(apiRoutes.generateOTP, payload).subscribe((response: any) => {
      //   console.log(response);
      //   if (response?.success == true) {
      //     this.otpField = false;
      //     this.verifiedOtp = true;
      //   }
      //   if (response?.success == false) {
      //     this.otpField = true;
      //     this.verifiedOtp = false;
      //     this.otpVerifyError = true;
      //   }
      // },
      //   (error: any) => {
      //     if (error?.success == false) {
      //       this.otpField = true;
      //       this.verifiedOtp = false;

      //     }
      //     this.alertService.failureAlert(error.error.message);
      //   });
    }
    // this.verifiedOtp = true;
  }
  handleCountdown(e: CountdownEvent) {
    if (e.left == 0) {
      this.countdownDone = false;
      // this._snackBar.dismiss();
    }
  };



  submitOTP() {
    this.btnDisableOtp = true;

    console.log(this.otp.value);
    this.loginForm.value.otp = this.otp.value;
    //delete this.loginForm.value.remember_me;

    this.apiCallMethodsService.post(apiRoutes.login, this.loginForm.value).subscribe((response: any) => {
      this.loginMessage = response;
      if (this.loginMessage?.success == true) {
        this.alertService.successAlert(response?.message);
        this.btnDisableOtp = false;
        setTimeout(() => {
          this.authService.removeId();
          this.authService.setToken(response);
          // this.router.navigate(['admin/business-management/select-company']);
          this.router.navigate(['/select-company']);

        }, 2000)
      };
    }, (error: any) => {
      this.errorOtpMessage = true;
      this.btnDisableOtp = false;

    });
  }


  resendEmailOtp() {
    this.btnDisableOtp = true;
    let payload = {
      "email": this.loginForm.value.email,
    }
    this.apiCallMethodsService.post(apiRoutes.resendLoginOTP, payload).subscribe((response: any) => {
      this.loginMessage = response;
      if (this.loginMessage?.success == true) {
        // this.alertService.successAlert(response?.message);
          this.countdownDone = true;
          this.btnDisableOtp = false;

          this.configureOtpAndCountdown()

      };
    }, (error: any) => {
      //  this.alertService.failureAlert(error.error.message);
      this.errorOtpMessage = true;
      this.btnDisableOtp = false;
    });

  }

  firstPage() {
    this.loginScreen = 1;
    this.errorOtpMessage = false;
  }

  ngOnInit(): void {
  }

}
