import { Component, EventEmitter, Input, Output, SimpleChanges } from '@angular/core';
import { countries } from 'src/app/constants/countryCodeData';

@Component({
  selector: 'app-contact-int-tel-picker',
  templateUrl: './contact-int-tel-picker.component.html',
  styleUrls: ['./contact-int-tel-picker.component.scss']
})
export class ContactIntTelPickerComponent {

  countriesList: any = null;
  selectedCountry: any = null;
  isInitialized: boolean = false;
  searchTerm: string = '';
  filteredCountriesList: any[] = [];
  
  @Output() countryConChange: EventEmitter<any> = new EventEmitter<any>();
  @Input() countryEdit: any;

  constructor() {

    this.countriesList = countries;

    this.countriesList.forEach(country => {

      country['flagUrl'] = 'https://flagcdn.com/64x48/' + country.iso.toLowerCase() + '.png';

    });
    this.filteredCountriesList = this.countriesList;
  }

  selectConCountry(country) {

    this.selectedCountry = country;
    localStorage.setItem('selectedConCountry', JSON.stringify(country));
    console.log(localStorage.getItem('selectedConCountry'));

    this.countryConChange.emit(this.selectedCountry.code);
  }

  stopPropagation(event: Event): void {
    event.stopPropagation();
  }

  ngOnInit() {

    // let preSelectedCountry = this.countriesList.find(x=> x.country == 'India');
    // this.selectCountry(preSelectedCountry);

    const savedCountry = localStorage.getItem('selectedConCountry');
    console.log(savedCountry);

    if (savedCountry) {
      this.selectedCountry = JSON.parse(savedCountry);
    } else {
      const preSelectedCountry = this.countriesList.find(x => x.country === 'India');
      this.selectConCountry(preSelectedCountry);
    }
    this.isInitialized = true;


    this.filteredCountriesList = this.countriesList;


  }

filterCountries() {
  this.filteredCountriesList = this.countriesList.filter(country =>
    country.country.toLowerCase().includes(this.searchTerm.toLowerCase()) ||
    country.code.includes(this.searchTerm)
  );
}

  ngOnChanges(changes: SimpleChanges) {
    setTimeout(() => {
      
      if (changes['countryEdit'] && this.countryEdit && this.isInitialized) {
        const dialCode = this.countryEdit['contact_dial_code'];
        const country = this.countriesList.find(c => c.code === dialCode?.substring(1));

        if (dialCode == null) {
          let preSelectedCountry = this.countriesList.find(x => x.country == 'India');
          this.selectConCountry(preSelectedCountry);
        } else{
          this.selectConCountry(country);
        }  
      }
    }, 100);
  }

}
