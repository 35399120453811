import { Component } from '@angular/core';
import { AuthService } from './authentication/services/auth.service';
import { Router, NavigationEnd, ActivatedRoute } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ApiCallMethodsService } from './shared/services/api-call-methods.service';
import { apiRoutes } from './constants/apiRoutes';
import { SidenavThemeServiceService } from './shared/services/sidenav-theme-service/sidenav-theme-service.service';
import { ToggleServiceService } from './shared/services/toggle-service/toggle-service.service';
import { NgZone } from '@angular/core';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  sidemenuChangeRoutes = ['/admin/business-management'];
  showSidemenu: any = true;

  constructor(public authService: AuthService,
    public router: Router,
    private modalService: NgbModal,
    private apiCallMethodsService: ApiCallMethodsService,
    private toggleService: ToggleServiceService,
    public themeService: SidenavThemeServiceService,
    private activatedRoute: ActivatedRoute,
    private zone: NgZone
  ) {
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        this.modalService.dismissAll();
        let result = this.sidemenuChangeRoutes.find(x => event.url.toLowerCase().includes(x.toLowerCase()));
        this.showSidemenu = result ? false : true;
      }
    });
    this.toggleService.isToggled$.subscribe(isToggled => {
      this.isToggled = isToggled;
      this.router.events.subscribe(event => {
        if (event instanceof NavigationEnd) {
          this.modalService.dismissAll();
          let result = this.sidemenuChangeRoutes.find(x => event.url.toLowerCase().includes(x.toLowerCase()));
          this.showSidemenu = result ? false : true;
        }
      });
    });

    // this.authService.removeLoginDetails();
    // this.authService.removeToken();
    // this.authService.removeId();

  }
  isBlankPage(url: string): boolean {
    // Check if the current route is one of the specified routes
    const isAuthRoute = ['/auth/create-password'].some(route => url.includes(route));

    // Check if it's an authentication route with a token query parameter
    const hasToken = url.includes('/auth/create-password') && this.activatedRoute.snapshot.queryParams['token'];

    // Return true if it's one of the specified routes or an authentication route with a token
    return isAuthRoute || hasToken;
  }
  isToggled = false;


  toggleRightSidebarTheme() {
    this.themeService.toggleRightSidebarTheme();
  }

  toggleHideSidebarTheme() {
    this.themeService.toggleHideSidebarTheme();
  }

  toggleCardBorderTheme() {
    this.themeService.toggleCardBorderTheme();
  }

  toggleTheme() {
    this.themeService.toggleTheme();
  }

  toggleCardBorderRadiusTheme() {
    this.themeService.toggleCardBorderRadiusTheme();
  }

  toggleRTLEnabledTheme() {
    this.themeService.toggleRTLEnabledTheme();
  }
  ngOnInit() {
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.zone.run(() => {
          window.scrollTo(0, 0);
          console.log('scroll');
        });
      }
    });
  }
}
