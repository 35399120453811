import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LoginComponent } from './components/login/login.component';
import { RouterModule } from '@angular/router';
import { authRoutes } from './routes/auth-routes';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ForgotPasswordComponent } from './components/forgot-password/forgot-password.component';
import { ResetPasswordComponent } from './components/reset-password/reset-password.component';
import { AuthService } from './services/auth.service';
import { MatFormField, MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatMenu, MatMenuModule } from '@angular/material/menu';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { NgOtpInputModule } from  'ng-otp-input';
import { CountdownModule } from 'ngx-countdown';
import { NgxUiLoaderModule, POSITION, SPINNER } from "ngx-ui-loader";
import { SignUpComponent } from './components/sign-up/sign-up.component';
import {MatStepperModule} from '@angular/material/stepper';
import { MatSelectModule } from '@angular/material/select';
import { CreatePasswordComponent } from './components/create-password/create-password.component';
import { NgxOtpInputModule } from "ngx-otp-input";
import { CountdownComponent } from 'ngx-countdown';
import { VerifyOtpComponent } from './components/verify-otp/verify-otp.component';
import { SharedModule } from '../shared/shared.module';
import { SelectCompanyComponent } from './components/select-company/select-company.component';
import { CompanyRegisterComponent } from './components/company-register/company-register.component';


@NgModule({
  declarations: [
    LoginComponent,
    ForgotPasswordComponent,
    ResetPasswordComponent,
    SignUpComponent,
    CreatePasswordComponent,
    VerifyOtpComponent,
    SelectCompanyComponent,
    CompanyRegisterComponent
  ],
  imports: [
    CommonModule,
    RouterModule.forChild(authRoutes),
    FormsModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatMenuModule,
    MatButtonModule,
    MatIconModule,
    NgOtpInputModule,
    CountdownModule,
    NgxUiLoaderModule,
    MatStepperModule,
    MatSelectModule,
    NgxOtpInputModule,
    MatStepperModule,
    CountdownComponent,
    SharedModule
  ],
  providers:[AuthService]

})
export class AuthenticationModule { }
